/* _ezentrum_customize_productdetail.scss for custom styling on all productdetail pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_productdetail {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					@include ezentrum_mixins.flex-flow(column,wrap);
					background-color:white;
					padding:ezentrum_variables.$global-padding;
					.ez_productdetail_top,
					.ez_productdetail_bottom {
						display:block;
						margin-bottom: ezentrum_variables.$global-margin;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,nowrap);
						}
					}
					.ez_productdetail_top {
						padding-top: ezentrum_variables.$global-padding;
						&:after {
							content:"";
							display:block;
							clear:both;
						}
						.ez_productimage_wrapper {
							padding:0;
							width:100%;
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								padding-right:ezentrum_variables.$global-padding;
								width:40%;
							}
							.ez_productimage {
								margin-top: ezentrum_variables.$global-margin;
								position:relative;
								position: sticky;
								top: 180px;
								img {
									max-height:450px;
									width:unset;
								}
								.siegel_wrapper {
									position:absolute;
									right:20px;
									width:60px;
									height:auto;
									text-align:center;
									font-size:0.7rem;
									line-height:0.7rem;
									img {
										width:60px;
									}
									p {
										margin:0;
									}
								}
								@include ezentrum_mixins.for-size(tablet-portrait-up) {
									padding-right: 4 * ezentrum_variables.$global-padding;
									min-height:650px;
									img {
										max-height:650px;
									}
									.siegel_wrapper {
										top:100px;
										width:80px;
										.siegel_element {
											height:80px;
										}
									}
								}
							}
						}
						.ez_productdescription_wrapper {
							display: block;
							padding: ezentrum_variables.$global-padding 0 0 0;
							width:100%;
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								padding: ezentrum_variables.$global-padding 0 0 ezentrum_variables.$global-padding;
								width:60%;
							}
							.ez_productheadline {
								h1 {
									margin-bottom: 2 * ezentrum_variables.$global-margin;
									font-size:1.2rem;
									font-weight:700;
									text-transform:unset;
									.erzeuger {
										font-size:0.8rem;
										font-weight:400;
									}
								}
							}
							.ez_productdescription {
								margin-top:80px;
								padding-right: ezentrum_variables.$global-padding;
								.bezeichnung_401 {
									margin-bottom: 2 * ezentrum_variables.$global-margin;
									font-weight:bold;
									color: ezentrum_custom_variables.$red;
								}
								p.ez-module-articletext strong,
								p.ez-module-articletext.red-text {
									color: ezentrum_custom_variables.$red;
								}
							}
						}
					.ez_productdetail_bottom {}
					}
					.ez_productinfos_wrapper {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(column,wrap);
						.ez_productinfos_left,
						.ez_productinfos_right {
							flex:1 0 250px;
							font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
							line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
						}
						.ez_productinfos_left {
							order:2;
							table.staffelpreise {
								margin-bottom:ezentrum_variables.$global-margin;
								width:100%;
								th, td {
									border-bottom:1px solid ezentrum_variables.$gray;
								}
								th {
									font-weight:400;
								}
							}
							.formate {
								position:relative;
								a {
									text-decoration:none;
								}
								.formate-titel {
									position:relative;
									margin: ezentrum_variables.$global-margin 0;
									padding: 0.5 * ezentrum_variables.$global-padding;
									border:1px solid ezentrum_variables.$primary-color;
									font-weight:400;
									.ez_icon {
										position: absolute;
										top: .4rem;
										right: .6rem;
										margin: 0;
										&.close {
											display:none;
										}
									}
								}
								.formate-content {
									display:none;
									position:absolute;
									padding:0;
									top:3rem;
									left:0;
									width:100%;
									background-color: ezentrum_variables.$white;
									border:1px solid ezentrum_variables.$gray;
									.formate-item {
										@include ezentrum_mixins.display-flex;
										@include ezentrum_mixins.flex-justify-content(space-around);
										@include ezentrum_mixins.flex-flow(row,wrap);
										padding: 0.5 * ezentrum_variables.$global-padding;
										border-bottom:1px solid ezentrum_variables.$gray;
										.formate-image {
											flex:40px 0 0;
											img {
												width:20px;
												max-width:100%;
											}
										}
										.formate-name {
											flex:calc(100% - 40px) 0 0;
											.formate-productnumber {}
											.formate-jahrgang {}
											.formate-price {
												font-weight:400;
											}
										}
									}
								}
								input[type="checkbox"]:checked ~ .formate-content {
									display:block;
								}
								input[type="checkbox"]:checked ~ .formate-titel .ez_icon.open {
									display:none;
								}
								input[type="checkbox"]:checked ~ .formate-titel .ez_icon.close {
									display:block;
								}
							}
						}
						.ez_productinfos_right {
							order:1;
						}
						@include ezentrum_mixins.for-size(desktop-up) {
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,wrap);
							.ez_productinfos_left,
							.ez_productinfos_right {
								flex:1 0 250px;
							}
							.ez_productinfos_left {
								order:1;
								padding-right: ezentrum_variables.$global-padding;
							}
							.ez_productinfos_right {
								order:2;
								padding-left: ezentrum_variables.$global-padding;
							}
						}
						width:100%;
						.ez_product {
							.lieferkommentar {
								display:block;
								font-weight:400;
								color:ezentrum_custom_variables.$red;
							}
							.ez_artnr {
								font-size:ezentrum_variables.$body-font-size;
							}
						}
						.ez_product_legalinformation {
							font-size: 0.85 * ezentrum_variables.$body-font-size;
							.ez_availability {
								&.avail_reservierbar .ez_availabilityicon {
									background-color:#f08b1e;
								}
								&.avail_subskription .ez_availabilityicon {
									background-color:#294b64;
								}
							}
						}
						.ez_priceinfos {
							margin-top:0;
							margin-bottom:0;
							padding-top:0;
							.ez_productprice {
								.ez_normalpreis,
								.ez_sonderpreis {
									font-size: 1.2rem;
									font-weight:700;
								}
								.ez_sonderpreis {
									color: ezentrum_custom_variables.$red;
								}
								.ez_grundpreis {
									color: ezentrum_variables.$body-font-color;
									font-size: 0.85 * ezentrum_variables.$body-font-size;
									font-weight:400;
								}
								.ez_addition {
									font-size: 0.85 * ezentrum_variables.$body-font-size;
								}
								.preisinfos {
									.pi_ust {
										&:after {
											content:"\a";
											white-space:pre;
										}
									}
									.pi_versand {}
								}
							}
						}
					}
					.ez_teasertext {
						width:100%;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							padding-left: ezentrum_variables.$global-padding;
							padding-right: 2 * ezentrum_variables.$global-padding;
							width:40%;
						}
					}
					.weindetails {
						position:relative;
						width:100%;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							width:60%;
							padding-left: ezentrum_variables.$global-padding;
						}
						.weininfo-left,
						.weininfo-right {
							a {
								color: ezentrum_variables.$body-font-color;
								&:hover {
									color: ezentrum_custom_variables.$red;
								}
							}
						}
					}
					.ez_alternatives_wrapper {
						.ez_alternatives {
							.ez_alternatives_item {
								background-color:rgb(237,237,237);
								.ez_product {
									.ez_productimage {
										background-color:#fff;
									}
									.ez_productname {
										margin-top:ezentrum_variables.$global-margin;
										h2 {
											color:ezentrum_variables.$body-font-color;
										}
									}
									.ez_productnumber {
										font-size:0.7 * ezentrum_variables.$body-font-size;
										line-height:0.7 * ezentrum_variables.$body-line-height;
									}
									.ez_availability {
										position: relative;
										top: unset;
										right: unset;
										float:left;
									}
								}
								.ez_producttext {
									display:none;
									position:absolute;
									top:0;
									left:0;
									padding:ezentrum_variables.$global-padding;
									width:100%;
									height:100%;
									background-color:rgba(226,3,0,0.6);
									color:ezentrum_variables.$white;
									text-decoration:none;
									span {
										display:block;
										margin:0;
										padding:0;
										width:100%;
										height:100%;
										overflow:hidden;
									}
								}
								&:hover .ez_producttext {
									display:block;
								}
							}
						}
					}
				}
			}
		}
	}
}

