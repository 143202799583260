﻿/* _ezentrum_customize.scss for individual styling elements */
/* ########## start: import ezentrum_variables ########## */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

/* ########## stop: import ezentrum_variables ########## */

/* Variables */
$offcanvas-width:250px;

html,
body {
	min-height: 100vh; // without the hight might be shorter than the current screen hight
	height: fit-content;
}
body {
	background-color:#cfdbda;
	background-image:url(/resources/images/pinard/background.jpg);
	letter-spacing: 0.04em;
	a {
		color:ezentrum_variables.$body-font-color;
		text-decoration:underline;
		&:hover {
			color:ezentrum_custom_variables.$red;
		}
	}
}
h1, h2, h3, h4, h5, h6 {
	font-weight:400;
}
h1 {
	margin-bottom:ezentrum_variables.$global-margin;
	font-size:1.2rem;
	text-transform:uppercase;
}
h2 {
	font-size:1.1rem;
}

b, strong {
	font-weight:400;
}
input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], textarea {
	border: 1px solid ezentrum_variables.$secondary-color;
	&:read-only {
		color: ezentrum_variables.$gray;
		cursor:default;
	}
	&::placeholder {
		color: ezentrum_variables.$secondary-color;
		//needs to be set because Firefox sets the opacitiy of placeholders to .54 by default and the used color here is too bright, so the placeholder text is barely be noticable
		opacity: 1;
	}
}
button, .button, input[type="button"], input[type="submit"], input.button, a.button {
	border:none;
	border-radius:ezentrum_variables.$global-radius;
	background-color: ezentrum_variables.$primary-color;
	color:ezentrum_variables.$body-font-color;
	text-decoration:none;
	font-size:1.07145 * ezentrum_variables.$body-font-size;
	font-weight:400;
	&:hover {
		background-color:ezentrum_variables.$body-font-color;
		color: ezentrum_variables.$primary-color;
	}
	&:disabled {
		background-color: lighten(ezentrum_variables.$primary-color,20);
		&:hover {
			color: ezentrum_variables.$white;
			cursor: not-allowed;
		}
	}
}

.anchor_wrapper {
	position:relative;
	.anchor {
		position:absolute;
		top:-150px;
		left:0;
	}
}
.ez_icon {
	width: 20px;
	height: 20px;
}

.small-text {
	font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
	line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
}

.nowrap {
	white-space:nowrap;
}

.ez_tobasket {
	margin-bottom: 0;
	height:35px;
}

.ez_productprice {
	.ez_normalpreis {}
	.ez_grundpreis {}
	.ez_sonderpreis {}
	.ez_basispreis {}
	.ez_currency {}
	.ez_addition {}
	.ez_sum {}
}

.ez_availability {
	display:inline-block;
	width:unset;
	height:unset;
	border-radius:unset;
	.ez_availabilityicon {
		display:inline-block;
		width:ezentrum_variables.$body-font-size;
		height:ezentrum_variables.$body-font-size;
		border-radius:50%;
		vertical-align: middle;
	}
	&.avail_good {
		color: ezentrum_variables.$body-font-color;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:#b0d063;
		}
	}
	&.avail_medium {
		color:ezentrum_custom_variables.$red;
		background-color:transparent;
		font-weight:400;
		.ez_availabilityicon {
			background-color:#ee8b30;
		}
	}
	&.avail_bad {
		color: ezentrum_variables.$body-font-color;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:#aa1c30;
		}
	}
	&.avail_reservierbar {
		color: ezentrum_variables.$body-font-color;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:#f08b1e;
		}
	}
	&.avail_subskription {
		color: ezentrum_variables.$body-font-color;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:#294b64;
		}
	}
}

/* Form Items */
.ez_form_item {
	display:block;
	.ez_label_wrapper {
		label {
			margin:0;
			font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
		}
	}
	.ez_input_wrapper {
		margin-bottom: ezentrum_variables.$global-margin;
		width:100%;
		&:last-child {
			&:after {
				content:"";
				display:block;
				clear:both;
				width:100%;
				height:1px;
			}
		}
		&.ez_textfield {
			height:2.3rem;
			border:1px solid ezentrum_variables.$gray;
			border-radius:ezentrum_variables.$global-radius;
			&.sort_page {
				width:100%;
				background-color:ezentrum_variables.$white;
				border-color:ezentrum_variables.$primary-color;
				select {
					width:calc(100% - #{ezentrum_variables.$global-padding});
					text-align:center;
				}
			}
		}
		&.street {
			width:70%;
			float:left;
			border-top-right-radius:0;
			border-bottom-right-radius:0;
		}
		&.number {
			width:30%;
			float:left;
			border-top-left-radius:0;
			border-bottom-left-radius:0;
			border-left:none;
		}
		&.zipcode {
			width:30%;
			float:left;
			border-top-right-radius:0;
			border-bottom-right-radius:0;
		}
		&.city {
			width:70%;
			float:left;
			border-top-left-radius:0;
			border-bottom-left-radius:0;
			border-left:none;
		}
		input, select, span {
			margin:0;
			border:none;
		}
		input, select {
			float:left;
			margin:0;
			padding:0.5 * ezentrum_variables.$global-padding;
			width:calc(100% - 2.4rem);
			background-color:transparent;
		}
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			-moz-border-radius: 0;
			-webkit-border-radius: 0;
			border-radius: 0;
			background: url(/resources/libraries/bootstrap-icons/chevron-down.svg) no-repeat;
			background-position: right center;
			background-size: 20px 20px;
		}
		@media (width < 600px) {
			.helper-item {
				position: relative;
				display: flex;
				width: 100%;
				>span:first-child {
					width: calc(100% - 2.4rem);
				}
			}
		}
		.ez_status {
			position:relative !important;
			float:right;
			margin:0;
			top:unset;
			right:unset;
			width:2.4rem;
			border-left: ezentrum_variables.$gray;
			svg {
				float:right;
				margin: 0.5 * ezentrum_variables.$global-margin;
				margin-right: ezentrum_variables.$global-margin;
			}
		}
		input[type="checkbox"] {
			display:none;
		}
		.ez_checkboxlabel {
			.ez_icon {
				position:absolute;
				top:15%;
				width:1rem;
				height:1rem;
				&.unchecked {
					display:block;
				}
				&.checked {
					display:none;
				}
			}
			&:hover {
				cursor: pointer;
			}
			span {
				display: block;
				padding-left:1.6rem;
			}
		}
		input[type="checkbox"]:checked ~ .ez_checkboxlabel .ez_icon {
			&.unchecked {
				display:none;
			}
			&.checked {
				display:block;
			}
		}
	}
}
@include ezentrum_mixins.for-size(tablet-portrait-up) {
	.ez_form_item {
		@include ezentrum_mixins.display-flex;
		@include ezentrum_mixins.flex-justify-content(space-between);
		@include ezentrum_mixins.flex-flow(row,wrap);
		.ez_label_wrapper {
			display:block;
			width:30%;
			label {
				font-size: ezentrum_variables.$body-font-size;
			}
		}
		.ez_input_wrapper {
			width:70%;
			&.street {
				width:60%;
			}
			&.number {
				width:10%;
			}
			&.zipcode {
				width:20%;
			}
			&.city {
				width:50%;
			}
			.password_checker {
				#password_helper {
					.helper-item:after {
						content: "";
						display: block;
						clear: both;
						width: 100%;
						height: 1px;
					}
				}
				.ez_input_wrapper {
					width:100%;
				}
			}
		}
	}
}

.ez_select {
	span {
		background-color: ezentrum_variables.$white;
		.ez_icon {
			position: absolute;
			top: calc((0.4 * 0.96rem) + (0.1 * 0.8rem));
			right: 8px;
			color: #002e4d;
			pointer-events: none;
		}
		select {
			color:ezentrum_variables.$body-font-color;
		}
	}
}

.ez_orderfields {
	@include ezentrum_mixins.display-flex;
	@include ezentrum_mixins.flex-justify-content(space-between);
	@include ezentrum_mixins.flex-flow(row,nowrap);
	margin-top: ezentrum_variables.$global-margin;
	margin-bottom: ezentrum_variables.$global-margin;
	width:100%;
	@include ezentrum_mixins.for-size(tablet-portrait-up) {
		float:right;
		width:280px;
	}
	> div {
		flex: 1 0 50%;
		.ez_fields_menge input[type="number"],
		.ez_fields_menge input[type="number"]:focus,
		.ez_fields_menge input[type="number"]:not(.browser-default):focus:not([readonly]) {
			flex: 1 0 50px;
		}
		.button.to_cart_button {
			width:100% !important;
			margin:0 !important;
		}
	}
}
.ez_fields_menge {
	margin-bottom: 0;
	height: calc(0.96rem + (1.5 * 0.8rem));
	background-color: ezentrum_variables.$white;
	// color:1px solid ezentrum_variables.$gray;
	.ez_minus, .ez_plus {
		flex: 0 0 35px;
		font-size:1.2rem;
		color: ezentrum_variables.$gray;
		&:active:hover {
			color: darken( ezentrum_variables.$gray, 30% );
		}
	}
	input[type="number"],
	input[type="number"]:focus,
	input[type="number"]:not(.browser-default):focus:not([readonly]) {
		margin:0;
		width:calc(100% - 70px);
		height:inherit;
		border:1px solid ezentrum_variables.$gray;
	}
}

hr {
	color: transparent;
	border-bottom: 1px solid hsl(45, 100%, 40%);
	&.primary {
		border-bottom: 1px solid ezentrum_variables.$primary-color;
	}
	&.secondary {
		border-bottom: 1px solid ezentrum_variables.$secondary-color;
	}
}
.ez_hr {
	color: #cc9800;
	height: 1px;
    background-color: #cc9800;
    border: 0 none;
    opacity: 1;
	margin: 0.938rem;
}

.ez_checkoutnavi {
	font-size:0.6rem;
	@include ezentrum_mixins.for-size(tablet-portrait-up) {
		font-size: ezentrum_variables.$body-font-size;
	}
	.ez_checkoutnavi_icon {
		color: red;
		svg.ez_icon {
			margin: auto;
			width: 36px;
			height: 36px;
			//color: ezentrum_variables.$white;
			color:red;
		}
	}
	.ez_checkoutnavi_icon {
		margin: 0 auto;
		width:ezentrum_variables.$checkoutnavi-icon-size;
		height:ezentrum_variables.$checkoutnavi-icon-size;
		border-radius: 50%;
		font-weight: bold;
		background-color:ezentrum_variables.$gray;
		i {
			margin:auto;
			color:ezentrum_variables.$white;
		}
		&:before,
		&:after {
			position: absolute;
			top: calc( ( #{ezentrum_variables.$checkoutnavi-icon-size} / 2 ) - ( 0.5em / 2 ) );
			left: 0px;
			right: 0px;
			height: .5em;
			content: "";
			background-color: ezentrum_variables.$gray;
		}
		&:before{
			left: 0;
			right:calc(50% + 20px);
		}
		&:after {
			left:calc(50% + 20px);
			right: 0;
		}
	}
	&:first-child .ez_checkoutnavi_icon:before {
		display:none;
	}
	&:last-child .ez_checkoutnavi_icon:after {
		display:none;
	}
	&.pre {
		.ez_checkoutnavi_icon,
		.ez_checkoutnavi_icon:before,
		.ez_checkoutnavi_icon:after {
			background-color: ezentrum_variables.$primary-color;
		}
	}
	&.current {
		.ez_checkoutnavi_icon,
		.ez_checkoutnavi_icon:before,
		.ez_checkoutnavi_icon:after {
		}
	}
	.ez_checkoutnavi_name {
		height: initial;
		line-height: 1.5em;
		display: block;
		text-align: center;
	}
}
a.ez_checkoutnavi_item {
	.ez_checkoutnavi_icon,
	.ez_checkoutnavi_name {
		&:hover {
			cursor: pointer;
		}
	}
}

.ez_checkoutnavi .ez_checkoutnavi_item.current .ez_checkoutnavi_icon,
.ez_checkoutnavi .ez_checkoutnavi_item.current .ez_checkoutnavi_icon:before,
.ez_checkoutnavi .ez_checkoutnavi_item.current .ez_checkoutnavi_icon:after {
	color:red;
}

.ez_offcanvas_menu {
	position:fixed;
	top:0;
	left:0;
	width:$offcanvas-width;
	height:100%;
	background-color:#e6e6e6;
	overflow-x: hidden;
	overflow-y: auto;
	z-index:0;
	transition: transform 0.5s ease;
	transform: translateX(-1 * $offcanvas-width);
	#ez_mobilenavi {
		ul {
			list-style-type:none;
			margin:0;
			padding:0;
		}
		ul.customer_cart {
			@include ezentrum_mixins.display-flex;
			@include ezentrum_mixins.flex-justify-content(space-between);
			@include ezentrum_mixins.flex-flow(row,wrap);
			li {
				flex:1 0 auto;
				padding:0.5 * ezentrum_variables.$global-padding;
				padding-bottom:ezentrum_variables.$global-padding;
				a {
					span.ez_cartpreview_positions {
						display:none;
					}
					span.text-preview {
						padding-left:0.5 * ezentrum_variables.$global-padding;
					}
				}
			}
		}
		ul.ez_level1 {
			li {
				label,
				a {
					position:relative;
					display:block;
					margin-bottom:0.5 * ezentrum_variables.$global-margin;
					padding:0.5 * ezentrum_variables.$global-padding;
					padding-right:1.2rem;
					font-weight: bold;
					background: rgb(222,222,222);
					background: linear-gradient(90deg, rgba(222,222,222,1) 0%, rgba(235,235,235,1) 54%, rgba(222,222,222,1) 100%);
					color:ezentrum_variables.$body-font-color;
					text-decoration:none;
					cursor:pointer;
					&:hover {
						color:ezentrum_custom_variables.$red;
					}
					&.selected {
						color: ezentrum_custom_variables.$red;
					}
				}
				label::after {
					position:absolute;
					top:0.4rem;
					right:0.6rem;
					margin:0;
					font-family: 'Font Awesome 5 Free';
					font-weight:900;
					content: "\f105";
				}
				.ez_level2 {
					height:0;
					overflow:hidden;
					> li {
						> label,
						> a {
							padding:0.3 * ezentrum_variables.$global-padding;
							padding-left:ezentrum_variables.$global-padding;
							border-left:6px solid #444;
							font-weight:normal;
							&.ez_container {
								padding-right:1.2rem;
								i {
									top:0.4rem;
								}
							}
						}
						.ez_level3 {
							height:0;
							overflow:hidden;
							> li {
								> label,
								> a {
									padding:0.3 * ezentrum_variables.$global-padding;
									padding-left:2 * ezentrum_variables.$global-padding;
									font-weight:normal;
									line-height:1rem;
									border-left: 6px solid #999;
								}
								.ez_level4 {
									height:0;
									overflow:hidden;
									> li {
										> a {
											padding:0.3 * ezentrum_variables.$global-padding;
											padding-left:3 * ezentrum_variables.$global-padding;
											font-size:0.85 * ezentrum_variables.$body-font-size;
											font-weight:normal;
											line-height:1rem;
											border-left: 6px solid #ccc;
										}
									}
								}
								label.ez_container_3-close {
									display:none;
								}
								input:checked ~ ul.ez_level4 {
									height:auto;
								}
								input:checked ~ label::after {
									content: "\f107";
								}
								input:checked ~ label.ez_container_3 {
									display:none;
								}
								input:checked ~ label.ez_container_3-close {
									display:block;
								}
							}
						}
						label.ez_container_2-close {
							display:none;
						}
						input:checked ~ ul.ez_level3 {
							height:auto;
						}
						input:checked ~ label::after {
							content: "\f107";
						}
						input:checked ~ label.ez_container_2 {
							display:none;
						}
						input:checked ~ label.ez_container_2-close {
							display:block;
						}
					}
				}
				label.ez_container-close {
					display:none;
				}
				input:checked ~ ul.ez_level2 {
					height:auto;
				}
				input:checked ~ label::after {
					content: "\f107";
				}
				input:checked ~ label.ez_container {
					display:none;
				}
				input:checked ~ label.ez_container-close {
					display:block;
				}
			}
		}
	}
}
input#offcanvas_control {
	display:none;
}
input:checked#offcanvas_control ~ .ez_offcanvas_menu {
	transform:translateX(0);
}
#ez_wrapper {
	transition: transform 0.5s ease;
}
input:checked#offcanvas_control ~ #ez_wrapper {
	transform:translateX($offcanvas-width);
	box-shadow: 0 0 10px rgba(10, 10, 10, 0.5);
}
#ez_offcanvas-toggle {
	display:block;
	position: absolute;
	top: 75px;
	left: 20px;
	width:20px;
	height:20px;
	z-index:1001;
	.ez_offcanvas_menu-button {
		position: relative;
		display:block;
		margin-top:20px;
		width: 16px;
		height: 2px;
		background: ezentrum_variables.$primary-color;
		&:before,
		&:after {
			display: block;
			content: "";
			height: 2px;
			width: 16px;
			background: ezentrum_variables.$primary-color;
			position: absolute;
			z-index: 0;
		}
		&:before {
			top: 6px;
		}
		&:after {
			top: -6px;
		}
	}
}
@include ezentrum_mixins.for-size(tablet-portrait-up) {
	#ez_offcanvas-toggle {
		display:block;
		position: absolute;
		top: 17px;
		left: 20px;
	}
}
@include ezentrum_mixins.for-size(tablet-landscape-up) {
	#ez_offcanvas-toggle {
		display:none;
	}
}
input:checked#offcanvas_control .ez_offcanvas_menu-button {
	-webkit-transition: background 0.1s 0s;
	-webkit-transition-delay: ease;
	-moz-transition: background 0.1s 0s ease;
	-o-transition: background 0.1s 0s ease;
	transition: background 0.1s 0s ease;
	background: transparent !important;
	&:before,
	&:after {
		background: white;
		-webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.2s;
		-webkit-transition-delay: 0s, ease;
		-moz-transition: top 0.1s ease, -moz-transform 0.1s 0.2s ease;
		-o-transition: top 0.1s ease, -o-transform 0.1s 0.2s ease;
		transition: top 0.1s ease, transform 0.1s 0.2s ease;
		top: 0;
		width: 20px;
	}
	&:before {
		-webkit-transform: rotate3d(0, 0, 1, 45deg);
		transform: rotate3d(0, 0, 1, 45deg);
	}
	&:after {
		-webkit-transform: rotate3d(0, 0, 1, -45deg);
		transform: rotate3d(0, 0, 1, -45deg);
	}
}

#lieferanschrift_neu_form {
	margin-top: ezentrum_variables.$global-margin;
	max-height:0;
	overflow:hidden;
	transition: max-height 1s;
	label {
		display:none;
	}
}
input#lieferanschrift_neu:checked ~ #lieferanschrift_neu_form {
	max-height:500px;
}

#ez_wrapper {
	position: relative;
	top: 0;
	bottom: 100%;
	left: 0;
	padding: 0;
	width: 100%;
	z-index: 1;

	#ez_header_fullwidth {
		position:relative;
		height:4rem;
		@include ezentrum_mixins.for-size(tablet-landscape-up) {
			position: -webkit-sticky;
			position:sticky;
			top:-3rem;
			height:11rem;
		}
		background-image:url(/resources/images/pinard/background.jpg);
		z-index: 100;
		#ez_mobilemenu_wrapper {
			height:0;
		}
		#ez_mobilemenu-toggle {
			position: fixed;
			top:28px;
			z-index: 110;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				position: absolute;
				top:70px;
				z-index: 120;
			}
			input {
				width:24px;
				height:20px;
			}
			span.burgericon {
				width:21px;
				height:2px;
				margin-bottom:3px;
				transform-origin:2px 2px;
				background-color:ezentrum_variables.$body-font-color;
			}
		}
		#ez_header1_fullwidth {
			display:none;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				display:block;
			}
			#ez_header1_wrapper {
				.ez_header1_section {
					width:100%;
					@include ezentrum_mixins.flex-justify-content(space-around);
					.ez_header1_item {
						padding:ezentrum_variables.$global-padding;
						font-size:12.48px;
						span {
							display: block;
							padding-top: 0.3rem;
						}
					}
				}
			}
		}
		#ez_header2_fullwidth {
			position:fixed;
			height:70px;
			overflow:hidden;
			background-color: darken(#cfdbda, 10%);
			overflow:visible;
			z-index: 101;
			/* z-index must be greater than 100 to not overlap with #ez_header3_fullwidth's content */
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				position:relative;
				height:7rem;
				background-color:transparent;
				z-index:unset;
			}
			#ez_header2_wrapper {
				position:relative;
				display:block;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					@include ezentrum_mixins.flex-flow(row,wrap);
				}
				.ez_header2_section {
					flex-grow:1;
					@include ezentrum_mixins.flex-justify-content(space-between);
					&.ez_logo_wrapper {
						flex-grow:4;
						margin-top:-0.5 * ezentrum_variables.$global-margin;
						padding:0;
						.ez_header2_item.logo {
							display:block;
							margin:0;
							padding:0;
							width:100%;
							text-align:center;
							a {
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									display:block;
									width:100%;
									text-align:center;
								}
								img.logo {
									margin:0 auto;
									margin-top: ezentrum_variables.$global-margin;
									max-width:180px;
									/* Sonderfall: alte Smartphones mit sehr kleinem Display */
									@media only screen and (max-width: 340px) {
										max-width:150px;
									}
								}
							}
						}
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							flex-grow:2;
							.ez_header2_item.logo {
								a {
									img.logo {
										margin:0;
										margin-top: ezentrum_variables.$global-margin;
										max-width:260px;
									}
								}
							}
						}
						@include ezentrum_mixins.for-size(desktop-up) {
							.ez_header2_item.logo {
								a {
									img.logo {
										max-width:350px;
									}
								}
							}
						}
					}
					&.ez_searchform_wrapper {
						position: absolute;
						top:ezentrum_variables.$global-margin;
						right:50px;
						&.guest {
							right:ezentrum_variables.$global-margin;
							margin-right:0;
						}
						flex-grow:1;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							right:70px;
							&.guest {
								right:ezentrum_variables.$global-margin;
								margin-right:0;
							}
						}
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							position:relative;
							top:unset;
							right:unset;
							flex-grow:2;
						}
						.ez_header2_item {
							position:relative;
							padding-right: ezentrum_variables.$global-padding;
							flex-grow:1;
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								padding-top:0;
								padding-right:0;
							}
							> label {
								margin-top: 0.5 * ezentrum_variables.$global-margin;
								text-align:right;
							}
							input#search_head_toggle {
								display:none;
								~label[for=search_head_toggle]:hover {
									cursor: pointer;
								}
							}
							#search_head {
								position:fixed;
								display:none;
								top: 70px;
								left:0;
								width: 100%;
							}
							input#search_head_toggle:checked ~ #search_head {
								display:block;
							}
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								#search_head {
									position:relative;
									display:flex;
									margin-left: 2rem;
									top:unset;
									right: unset;
									width:calc(100% - 2rem);
								}
								#search_head_toggle ~ label {
									display:none;
								}
							}
							.ez_searchform {
								width:100%;
								.ez_searchcontainer {
									width:100%;
									height:40px;
									#ez_search_delete {
										position: absolute;
										right: 60px;
										top: 50%;
										transform: translateY(-50%);
										color: #6c757d;
										cursor: pointer;
										&:hover { color: ezentrum_variables.$alert-color; }
									}
								}
								#search_output {
									position: absolute;
									box-shadow: 1px 1px 5px #d3d3d3;
									border-radius: ezentrum_variables.$global-radius;
									background: white;
									margin-top: 0.8rem;
									height: 80vh;
									max-height: 800px;
									// max-width: 600px;
									width: inherit;
									margin-right: 0;
									overflow-y: auto;
									display: none;
									&[data-ez-open="true"] { display: block; }
									h3 {
										padding-bottom: 0.4rem;
										border-bottom: 1px solid #d3d3d3;
									}
									a:hover { text-decoration: none; }
									.search_container {
										.search_headline {
											margin-top: 0.8rem;
											padding-bottom: 0.4rem;
											border-bottom: 1px solid #d3d3d3;
										}
										.search_content {
											display: flex;
											flex-direction: row;
											flex-wrap: wrap;
											justify-content: flex-start;
											overflow-y: auto;
											span {
												&.productbrand {
													font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
													line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
													font-weight: 400;
												}
												margin: 0 .8rem .8rem 0;
												a {
													display: block;
													margin: 0;
													padding: 0.4rem;
													width: unset;
													font-weight: 400;
													border: 1px solid ezentrum_variables.$primary-color;
													&.ez_autocomplete_button {
														font-size: ezentrum_variables.$body-font-size;
														line-height: calc(0.75 * #{ezentrum_variables.$body-line-height});
														text-decoration:none;
													}
												}
											}
											.search_item.product {
												padding-bottom: 0.4rem;
												width: 100%;
												.productimage {
													float: left;
													width: 60px;
													height: 60px;
													display: flex;
													justify-content: center;
													img {
														max-width: 100%;
														max-height: 100%;
														margin: 0.313rem;
													}
												}
												.productname {
													float: left;
													padding-left: 0.8rem;
													width: calc(100% - 60px);
													line-height: normal;
													.productnumber {
														margin: 0 .8rem .8rem 0;
													}
												}
												.ez_productprice {
													line-height: 0.94rem;
													text-align:left;
													span:not(:first-child) {
														font-size: calc(0.75 * #{ezentrum_variables.$body-font-size});
													}
													.ez_price_container { letter-spacing: 0px; }
													.ez_normalpreis, .ez_sonderpreis {
														font-size: 1.2 * ezentrum_variables.$body-font-size;
														font-weight: 700;
													}
													.ez_normalpreis { }
													.ez_sonderpreis { color: ezentrum_custom_variables.$red; }
													.ez_grundpreis {
														font-weight:700;
														color: ezentrum_variables.$body-font-color;
													}
													.ez_availability {
														position: relative;
														margin: 0;
														top: 0;
														right:0;
													}
												}
											}
										}
									}
								}
							}
						}
					}
					&.right {
						flex-grow:0;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							@include ezentrum_mixins.display-flex;
						}
						.ez_header2_item {
							padding-top:2px;
						}
					}
					.ez_header2_item {
						.ez_searchcontainer {
							width: 250px;
							border:none;
							border: 1px solid ezentrum_variables.$primary-color;
							.ez_searchbutton {
								width:40px;
								border-left:none;
								background-color:rgb(218,193,115);
								&::after {
									content:"";
									display: block;
									margin-left:0;
									margin-right: -2px;
									width: 18px;
									height: 18px;
									background-image:url(/resources/images/pinard/icon-search.png);
									background-repeat: no-repeat;
									background-size: contain;
								}
								&:hover {
									background-color:#002E4D;
									&::after {
										background-image:url(/resources/images/pinard/icon-search-white.png);
									}
								}
							}
						}
						nav.ez_headernavi_right {
							margin-right: ezentrum_variables.$global-margin;
							ul {
								float:right;
								margin-top:ezentrum_variables.$global-margin;
								list-style-type: none;
								li {
									float: left;
									margin-right:ezentrum_variables.$global-margin;
									a, span {
										font-weight:normal;
										color:ezentrum_variables.$header2-link-color;
										&:hover {
											color:ezentrum_variables.$header2-link-color-hover;
										}
									}
									.ez_icon {
										margin-left:0.5 * ezentrum_variables.$global-margin;
										margin-right:0.5 * ezentrum_variables.$global-margin;
										width:32px;
										height:32px;
										@include ezentrum_mixins.for-size(tablet-portrait-up) {
											width:36px;
											height:36px;
										}
									}
									i.fas {
										font-size:ezentrum_variables.$body-font-size;
										padding-top: 5px;
									}
									span {
										padding-left:ezentrum_variables.$global-padding;
									}
									.dropdown-menu {
										border:1px solid ezentrum_variables.$primary-color;
										a.dropdown-item {
											font-size:0.85 * ezentrum_variables.$body-font-size;
											font-weight:normal;
											color:ezentrum_variables.$body-font-color;
										}
									}
									.ez_to_cart_button {
										i, span {
											color:ezentrum_variables.$white;
										}
									}
									&.customer {
										position:fixed;
										top:1.5 * ezentrum_variables.$global-margin;
										left:50px;
										@include ezentrum_mixins.for-size(tablet-portrait-up) {
											top:ezentrum_variables.$global-margin;
											left:60px;
										}
										@include ezentrum_mixins.for-size(tablet-landscape-up) {
											position:relative;
											top:unset;
											left:unset;
										}
										.dropdown > a#user-menu:after {
											display:none;
										}
									}
									&.contact,
									&.imprint {
										display:none;
										@include ezentrum_mixins.for-size(tablet-landscape-up) {
											display:list-item;
										}
									}
									&.ez_cartpreview {
										position:fixed;
										top:1.5 * ezentrum_variables.$global-margin;
										right:ezentrum_variables.$global-margin;
										@include ezentrum_mixins.for-size(tablet-portrait-up) {
											top:ezentrum_variables.$global-margin;
										}
										@include ezentrum_mixins.for-size(tablet-landscape-up) {
											position:relative;
											top:unset;
											right:unset;
										}
										.ez_cartpreview_positions {
											position: absolute;
											display: block;
											top:16px;
											right: 2px;
											margin: 0;
											padding: 0;
											width:14px;
											height:14px;
											text-align: center;
											font-size:0.6 * ezentrum_variables.$body-font-size;
											line-height:0.6 * ezentrum_variables.$body-line-height;
											color: ezentrum_variables.$white;
											background-color:ezentrum_custom_variables.$red;
											border-radius: 50%;
											@include ezentrum_mixins.for-size(tablet-portrait-up) {
												width:20px;
												height:20px;
												font-size:0.85 * ezentrum_variables.$body-font-size;
												line-height:0.85 * ezentrum_variables.$body-line-height;
											}
										}
									}
									&:last-child {
										margin-right:0;
									}
								}
							}
						}
					}
				}
			}
		}
		@include ezentrum_mixins.for-size(phone-only) {
			#ez_header2_fullwidth {
				#ez_header2_wrapper {
					margin:0;
					padding:0;
					.ez_header2_section {
						margin:0;
						padding:0;
						.ez_header2_item {
							margin:0;
							padding:0;
						}
					}
					.ez_header2_section:first-child {
						.ez_header2_item {
							position:relative;
							overflow: visible;
						}
					}
					.ez_header2_section:nth-child(2) {
						.ez_header2_item.navi-right {
							width:100%;
						}
						.ez_header2_item:first-child {
							overflow-y: auto;
							 ul .ez_languageselect {
							 	margin-left:0;
							 }
						}
					}
				}
			}
		}
		#ez_header3_fullwidth {
			display: none;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				@include ezentrum_mixins.display-flex;
			}
			#ez_header3_wrapper {
				position:relative;
				flex-flow: row-reverse;
				align-content: flex-end;
				.ez_header3_section {
					width:100%;
					.ez_header2_item {
						width:100%;
					}
				}
				nav.ez_headernavi {
					margin-right: ezentrum_variables.$global-margin;
					float:right;
					ul {
						position:relative;
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-around);
						@include ezentrum_mixins.flex-flow(row,wrap);
						list-style-type:none;
						margin:0;
						padding:0;
						> li {
							display:none;
							&.ez_level1,
							&.ez_level2,
							&.ez_level3 {
								display:block;
							}
							&.ez_level1 {
								position:relative;
								&.weine {
									position:unset;
									> ul.ez_level2 {
									}
								}
								> ul.ez_level2 {
									position:absolute;
								}
								> a {
									padding:0 ezentrum_variables.$global-padding;
								}
							}
						}
						li.selected {
							> a, > label {
								color:ezentrum_custom_variables.$red;
							}
						}
						li {
							margin:0;
							padding:0;
							float:left;
							font-size:0.85 * ezentrum_variables.$body-font-size;
							b {
								font-weight:700;
							}
							label {
								padding:0 ezentrum_variables.$global-padding;
								padding-top:0;
								font-size:0.85 * ezentrum_variables.$body-font-size;
								font-weight:400;
								text-transform:uppercase;
								cursor:pointer;
								color:ezentrum_variables.$header2-link-color;
								&:hover {
									color:ezentrum_custom_variables.$red;
								}
								&#menu-close-area {
									position:fixed;
									display:block;
									top:0;
									left:0;
									width:100%;
									height:100%;
									background:transparent;
									cursor:default;
									z-index:8;
								}
							}
							input#menu-close:checked ~ label#menu-close-area {
								display:none;
							}
							>a {
								padding:0;
								display:block;
								color:ezentrum_variables.$header2-link-color;
								text-decoration:none;
								font-weight:400;
								&:hover {
									color:ezentrum_custom_variables.$red;
									// text-decoration:underline;
								}
								&.ez_level1 {
									text-decoration:none;
									text-transform:uppercase;
								}
								&.b2c {
									color:ezentrum_custom_variables.$red;
								}
							}
							ul.ez_level2 {
								display:none;
								position:absolute;
								top:calc(2rem - 1px);
								right:-1 * ezentrum_variables.$global-margin;
								padding-top: ezentrum_variables.$global-padding;
								padding-right: 4 * ezentrum_variables.$global-padding;
								padding-bottom: 2 * ezentrum_variables.$global-padding;
								padding-left: 4 * ezentrum_variables.$global-padding;
								width:calc(#{ezentrum_variables.$global-width} - (2 * #{ezentrum_variables.$global-margin}));
								@media screen and (min-width:899px) and (max-width:1325px) {
									width: 98.1vw;
								}
								background-color:white;
								box-shadow: 0 0.195rem 0.195rem rgba(0, 0, 0, 0.2);
								border: 1px solid #D7B144;
								border-bottom: 0 solid #D7B144;
								z-index:10;
								li {
									width:25%;
									.headline {
										display:block;
										padding:ezentrum_variables.$global-padding 0;
										font-weight:700;
										text-transform: uppercase;
									}
									a {
										.pinard_icon {
											width:16px;
											height:16px;
										}
									}
									ul.ez_level3 {
										li {
											display:block;
											width:100%;
										}
									}
								}
								&.small {
									width:217px;
									left:-1 * ezentrum_variables.$global-margin;
									right:unset;
									> li {
										width:100%;
									}
								}
							}
							label.ez_container-close {
								display:none;
							}
							input:checked ~ ul.ez_level2 {
								display:block;
							}
							input:checked ~ label.ez_container {
								display:none;
							}
							input:checked ~ label.ez_container-close {
								display:block;
							}
						}
					}
				}
				&:after {
					position:absolute;
					display: block;
					bottom:0;
					left:0;
					width:calc(100% - (2 * #{ezentrum_variables.$global-margin}));
					height:1px;
					content: "";
					margin: 0 ezentrum_variables.$global-margin;
					border-bottom: solid 1px ezentrum_variables.$primary-color;
				}
			}
		}
		@include ezentrum_mixins.for-size(phone-only) {
			#ez_header3_fullwidth {
				background-color: #ddd;
				#ez_header3_wrapper {
					.ez_header3_section {
						width:calc(100% + #{ezentrum_variables.$global-margin} + #{ezentrum_variables.$global-margin});
						&:nth-child(2) {
							display:none;
						}
						.ez_header3_item {
							width:calc(100% + #{ezentrum_variables.$global-margin} + #{ezentrum_variables.$global-margin});
							.ez_searchform {
								.ez_searchcontainer {
									.ez_searchinput:focus ~ .ez_searchoptions,
									.ez_searchoptions:hover {
										display:block;
										height:90px;
										z-index:1002;
										label {
											width:calc(100% - #{ezentrum_variables.$global-margin});
											height:calc(#{ezentrum_variables.$body-font-size} + #{ezentrum_variables.$global-padding});
											&:first-child {
												margin-bottom:ezentrum_variables.$global-margin;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		#ez_mobilemenu {
			position:fixed;
			height: 100%;
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					padding:0;
				}
				a {
					display: block;
					width: 85%;
					color: ezentrum_variables.$body-font-color;
				}
				svg.ez_icon {
					float:right;
				}
				.pinard_icon {
					width:16px;
					height:16px;
				}
			}
			ul.ez_level1 {
				&.topmenu {
					height:40px;
					border-bottom:1px solid #d6ad33;
					li {
						margin:0 ezentrum_variables.$global-margin;
						float:left;
						&.customer {
							div .dropdown-menu.show {
								margin-top:2rem !important;
								margin-left: -1 * ezentrum_variables.$global-margin !important;
								font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
								border:1px solid ezentrum_variables.$primary-color;
							}
						}
						&.ez_cartpreview {
							border-bottom:none;
						}
					}
					.ez_icon {
						width:32px;
						height:32px;
					}
				}
				> li.ez_level1 {
					border-bottom: 1px solid ezentrum_variables.$primary-color;
				}
				li.ez_level1 {
					> span.ez_container {
						p {
							margin-bottom: 0;
							 a {
								display: block;
								width: 100%;
								span {
									display: block;
									width: 100%;
								}
							}
						}
					}
					> a,
					> label,
					> span.subline,
					> span.ez_container {
						position: relative;
						display: block;
						margin-bottom:0;
						padding: 0.5 * ezentrum_variables.$global-padding;
						font-weight:400;
						color: ezentrum_variables.$body-font-color;
						text-decoration: none;
						text-transform:uppercase;
						&:hover {
							color: ezentrum_custom_variables.$red;
						}
						&.selected,
						&.selected a {
							color: ezentrum_custom_variables.$red;
						}
						&.b2c {
							color:ezentrum_custom_variables.$red;
						}
					}
					ul.ez_level2 {
						height: 0;
						overflow: hidden;
						li {
							> a,
							> label,
							> span.subline,
							> span.ez_container {
								margin: 0;
								padding: 0.5 * ezentrum_variables.$global-padding;
								font-weight:300;
								font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
								text-decoration:none;
								line-height:1rem;
								border-bottom: none;
/*
								label {
									border: 1px solid ezentrum_variables.$primary-color;
									background-color:transparent;
									svg.ez_icon {
										color: ezentrum_custom_variables.$red;
									}
								}
*/
							}
							> span.subline {
								font-weight: 400;
							}
							&.selected > a,
							&.selected > label {
								color: ezentrum_custom_variables.$red;
							}
							&:last-child {
								margin-bottom: ezentrum_variables.$global-margin;
							}
							ul.ez_level3 {
								display:none;
								overflow: hidden;
								> li {
									> a,
									> label,
									> span.subline,
									> span.ez_container {
										padding-top: 0;
										padding-right: 0.5 * ezentrum_variables.$global-padding;
										padding-bottom: 0.5 * ezentrum_variables.$global-padding;
										padding-left: 1.2 * ezentrum_variables.$global-padding;
									}
									ul.ez_level4 {
										height: 0;
										overflow: hidden;
										li {
											> a,
											> span.subline,
											> span.ez_container {
												margin-left: 2 * ezentrum_variables.$global-margin;
											}
										}
									}
									label.ez_container_3-close {
										display: none;
									}
									input:checked ~ ul.ez_level4 {
										height: auto;
									}
									input:checked ~ label.ez_container_3 {
										display: none;
									}
									input:checked ~ label.ez_container_3-close {
										display: block;
									}
								}
							}
							label.ez_container_2-close {
								display: none;
							}
							input:checked ~ ul.ez_level3 {
								display:block;
							}
							input:checked ~ label.ez_container_2 {
								display: none;
							}
							input:checked ~ label.ez_container_2-close {
								display: block;
							}
						}
					}
					label.ez_container_1-close {
						display: none;
					}
					input:checked ~ ul.ez_level2 {
						height: auto;
					}
					input:checked ~ span label.ez_container_1 {
						display: none;
					}
					input:checked ~ span label.ez_container_1-close {
						display: block;
					}
				}
			}
		}
	}
	#ez_content_fullwidth {
		position:relative;
		#ez_content_wrapper {
			.ez_content_reduced_width {
				padding: ezentrum_variables.$global-padding;
			}
			.ez_breadcrumbs {
				margin-top:2 * ezentrum_variables.$global-margin;
				margin-bottom:2 * ezentrum_variables.$global-margin;
				padding-left: ezentrum_variables.$global-padding;
				font-size:0.85 * ezentrum_variables.$body-font-size;
				.ez_breadcrumb_item,
				.ez_breadcrumb_spacer {
					margin:0;
					a {
						text-decoration:none;
					}
				}
				.ez_breadcrumb_spacer {
					padding:0 0.5 * ezentrum_variables.$global-padding;
					.ez_icon {
						width:9px;
						height:9px;
					}
				}
			}
/* alte Bannerdarstellung
			#ez_banner_block {
				margin-bottom: ezentrum_variables.$global-margin;
				width: 100%;
				img {
					width:100%;
					height:unset;
				}
			}
*/
			.ez_banner_block {
				@include ezentrum_mixins.display-flex;
				@include ezentrum_mixins.flex-justify-content(space-between);
				@include ezentrum_mixins.flex-flow(row,wrap);
				margin-left: -1 * ezentrum_variables.$global-margin;
				margin-right: -1 * ezentrum_variables.$global-margin;
				margin-bottom: ezentrum_variables.$global-margin;
				width: calc(100% + (2 * #{ezentrum_variables.$global-margin}));
				height: 100px;
				overflow:hidden;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					margin-left:unset;
					margin-right:unset;
					width: 100%;
					height:190px;
				}
				img {
					width:100%;
					height:unset;
				}
				.ez_banner_image {
					position:relative;
					flex: 1 0 66%;
					height: 100%;
					background-position:center center;
					background-repeat:no-repeat;
					background-size:cover;
					overflow:hidden;
					h1 {
						position:absolute;
						left:ezentrum_variables.$global-margin;
						top:3.5rem;
						width:90%;
						height:2.5rem;
						font-size:1.4rem;
						font-weight:700;
						color:rgb(250,250,220);
						overflow:hidden;
						text-shadow:0 0 3px ezentrum_variables.$gray;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							font-size:2.4rem;
						}
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							left:3.2rem;
							top:7.4rem;
							height:5rem;
							font-size:3rem;
						}
					}
				}
				.ez_banner_text_wrapper {
					display:none;
					flex: 1 0 33%;
					padding: 2 * ezentrum_variables.$global-padding;
					height: 100%;
					background-color:ezentrum_variables.$white;
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						display:block;
					}
					.ez_banner_text_content {
						width:100%;
						height:100%;
						overflow:hidden;
					}
				}
			}
			.ez_category_description {
				margin-top: -1 * ezentrum_variables.$global-margin;
				padding: 2 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
				width:100%;
				background-color: ezentrum_variables.$white;
				margin-bottom: .8rem;
				h1 {
					text-transform: unset;
				}
				h3 {
					font-size: 0.91rem;
					line-height: 1.04rem;
					font-weight: 400;
					margin-bottom: 0.845rem;
					text-transform: uppercase;
				}
			}
			#ez_sidenavi_container {
				.filter #ez_sidenavi.ez_level1 {
					margin-top:2.2rem;
					>li.filter_element {
						a {
							font-weight: 700;
						}
						input[type=checkbox] {
							display:none;
						}
						input[type=checkbox] ~ label {
							svg.ez_icon.left {
								position:relative;
								margin-top:3px;
								margin-left:9px;
								top:unset;
								left:unset;
								width:16px;
								height:16px;
								display:block;
								float:left;
								&.selected {
									display:none;
								}
							}
						}
						input[type=checkbox]:checked ~ label {
							color:ezentrum_custom_variables.$red !important;
							svg.ez_icon.left {
								display:none;
								&.selected {
									display:block;
								}
							}
						}
					}
				}
				#ez_sidenavi {
					&.ez_level1 {
						border-bottom:1px solid ezentrum_variables.$primary-color;
						li:first-child a,
						li:first-child label,
						li:first-child span {
							border-top:none;
						}
						> li {
							border:none;
							border-radius:ezentrum_variables.$global-radius;
							&.filter_element {
								margin-bottom:0;
								label {
									text-transform:lowercase;
									&:first-letter {
										text-transform:uppercase;
									}
								}
							}
							> label,
							> a,
							> span {
								position:relative;
								display:block;
								margin:0;
								padding:0.5 * ezentrum_variables.$global-padding;
								padding-left: ezentrum_variables.$global-padding;
								padding-right:1.5rem;
								font-weight: 400;
								background-color:transparent;
								color:ezentrum_variables.$body-font-color;
								text-decoration:none;
								border-top:1px solid ezentrum_variables.$primary-color;
								cursor:pointer;
								&:hover {
									color:ezentrum_custom_variables.$red;
								}
								&.selected {
									color:ezentrum_custom_variables.$red;
								}
								svg.ez_icon {
									position:absolute;
									top:0.4rem;
									right:0.6rem;
									margin:0;
								}
							}
							> ul.ez_level2 {
								> li {
									> a,
									> label,
									> span {
										position:relative;
										margin:0;
										padding:0.5 * ezentrum_variables.$global-padding;
										padding-left: ezentrum_variables.$global-padding;
										padding-right: 1.5rem;
										font-weight:400;
										border-top:1px solid ezentrum_variables.$primary-color;
										background-color:transparent;
										cursor:pointer;
										&.close {
											display:none;
										}
										&:hover {
											color:ezentrum_custom_variables.$red;
										}
										&.selected {
											color:ezentrum_custom_variables.$red;
										}
										svg.ez_icon {
											position:absolute;
											top:0.4rem;
											right:0.6rem;
											margin:0;
										}
									}
									> span.subline {
										font-weight:bold;
									}
									ul.ez_level3.accordionnavi {
										display:none;
									}
									input.accordionnavi:checked ~ label.open {
										display:none;
									}
									input.accordionnavi:checked ~ label.close {
										display:block;
									}
									input.accordionnavi:checked ~ ul.ez_level3.accordionnavi {
										display: block;
									}
									ul.ez_level3.accordionnavi li > ul {
										display:block;
									}
									> ul {
										padding-bottom: ezentrum_variables.$global-padding;
										> li {
											> a,
											> label,
											> span {
												position:relative;
												margin:0;
												padding:0.5 * ezentrum_variables.$global-padding;
												padding-right: 1.5rem;
												padding-left:2 * ezentrum_variables.$global-padding;
												font-size:0.85 * ezentrum_variables.$body-font-size;
												font-weight:300;
												line-height:1rem;
												background-color:transparent;
												cursor:pointer;
												&:hover {
													color:ezentrum_custom_variables.$red;
												}
												svg.ez_icon {
													position:absolute;
													display:block;
													top:0.4rem;
													right:0.6rem;
													margin:0;
													&.selected {
														display:none;
													}
												}
												&.selected {
													color:ezentrum_custom_variables.$red;
													svg.ez_icon {
														display:none;
														&.selected {
															display:block;
														}
													}
												}
											}
											> ul {
												> li {
													> a,
													> label,
													> span {
														margin:0;
														padding:0.3 * ezentrum_variables.$global-padding;
														padding-left:3 * ezentrum_variables.$global-padding;
														font-size:0.85 * ezentrum_variables.$body-font-size;
														font-weight:300;
														line-height:1rem;
														background-color:transparent;
														cursor:pointer;
														&:hover {
															color:ezentrum_custom_variables.$red;
														}
														&.selected {
															color:ezentrum_custom_variables.$red;
														}
													}
													> span.subline {
														font-weight:bold;
													}
												}
											}
										}
									}
								}
							}
							label.ez_container-close {
								display:none;
							}
							input:checked ~ ul.ez_level2,
							input:checked ~ ul.ez_level3,
							input:checked ~ ul.ez_level4 {
								height:auto;
							}
							input:checked ~ label.ez_container {
								display:none;
							}
							input:checked ~ label.ez_container-close {
								display:block;
							}
						}
					}
				}
			}

			#slider {
				margin:0 0 ezentrum_variables.$global-margin 0;
				padding:0;
			}
			#ez_content {
				#ez_login {
					padding-top: ezentrum_variables.$global-padding;
					padding-bottom: ezentrum_variables.$global-padding;
					background-color: ezentrum_variables.$white;
					&.kundendaten {
						[data-ez-module-formvalidator] .form_item[data-ez-valid="true"] {
							.ez_icon.true {
								display:none;
							}
						}
					}
				}
				#error_box {
					margin:0;
					padding:0;
					color: ezentrum_variables.$alert-color;
					li { list-style: none; }
				}
				#ez_startpagenavi_container {
					margin:0 -1 * ezentrum_variables.$global-margin;
					#ez_startpagenavi {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(row,wrap);
						div.ez_startpagenavi_item {
							position:relative;
							margin:ezentrum_variables.$global-margin;
							padding:0;
							width:220px;
							flex:1 0 auto;
							border-radius: ezentrum_variables.$global-radius;
							> a, > label {
								display:block;
								margin:0;
								padding:ezentrum_variables.$global-padding;
								width:100%;
								height:100%;
								text-align:center;
								background-color: rgb(222,222,222);
								background: linear-gradient(180deg, rgba(222,222,222,1) 0%, rgba(237,237,237,1) 54%, rgba(184,184,184,1) 100%);
								background-size:contain;
								background-repeat:no-repeat;
								background-position:center;
								color: ezentrum_variables.$primary-color;
								border-radius: ezentrum_variables.$global-radius;
								cursor:pointer;
								&:hover {
									text-decoration:underline;
								}
								h2 {
									height:2.4 * ezentrum_variables.$body-line-height;
									color:ezentrum_variables.$body-font-color;
									word-break:break-word;
									-moz-hyphens:auto;
									-webkit-hyphens:auto;
									hyphens:auto;
								}
								img {
									max-width:100%;
									max-height:ezentrum_variables.$productlist-imageheight;
								}
							}
							&.dummy {
								height:1px;
							}
							div.ez_level2 {
								display:none;
								position:absolute;
								top:0;
								left:0;
								padding:ezentrum_variables.$global-padding;
								width:100%;
								background-color:white;
								border:1px solid ezentrum_variables.$gray;
								z-index:100;
								label {
									position:absolute;
									top:-8px;
									right:-8px;
									margin:0;
									padding:0 0.5 * ezentrum_variables.$global-padding;
									width:unset;
									border-radius:50%;
									background-color:white;
									border:1px solid ezentrum_variables.$gray;
									cursor:pointer;
								}
								div {
									padding-bottom:0.5 * ezentrum_variables.$global-padding;
									a {
										color:ezentrum_variables.$body-font-color;
									}
								}
							}
							input:checked ~ div.ez_level2 {
								display:block;
							}
						}
					}
				}

				#vertriebspartner_list {
					margin-top: ezentrum_variables.$global-margin;
					padding: ezentrum_variables.$global-padding;
					background-color: ezentrum_variables.$white;
					.ez_button_white {
						background-color:white;
						border:1px solid ezentrum_custom_variables.$light-yellow;
					}
					.winzer_list {
/*
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(column,wrap);
*/
						display:block;
						margin:-1 * ezentrum_variables.$global-margin;
						margin-top: ezentrum_variables.$global-margin;
						padding:0;
						.winzer {
/*
							flex: 1 0 auto;
							margin:-1 * ezentrum_variables.$global-margin;
							padding:ezentrum_variables.$global-padding;
*/
							margin:0;
							padding:0 ezentrum_variables.$global-padding;
							word-break:break-word;
							border-bottom:1px solid lightgrey;
							&.dummy {
								display:block !important;
							}
							a {
								display:block;
/*
								margin: ezentrum_variables.$global-margin;
								padding-bottom: ezentrum_variables.$global-padding;
								height:180px;
*/
								text-decoration:none;
								color: ezentrum_variables.$body-font-color;
								overflow:hidden;
								h3 {
									display:inline-block;
									float:left;
									margin: 0.5 * ezentrum_variables.$global-margin 0;
									width:50%;
									min-height:12px;
									font-size: 0.85 * ezentrum_variables.$body-font-size;
									overflow:hidden;
								}
								.location {
									display:inline-block;
									float:left;
									margin: 0.5 * ezentrum_variables.$global-margin 0;
									width:50%;
									min-height:12px;
									font-size: 0.85 * ezentrum_variables.$body-font-size;
									line-height: ezentrum_variables.$body-font-size;
									overflow:hidden;
								}
								.comment {
									display:none;
								}
								.image {
									height:100%;
									display:none;
									background-color:rgb(218,227,165);
									background-size:cover;
									background-position:center center;
									overflow:hidden;
								}
								.info {}
								&:hover {
									color: ezentrum_custom_variables.$red;
								}
							}
						}
					}
				}
				#vertriebspartner_detail {
					margin-top:2 * ezentrum_variables.$global-margin;
					padding-top: ezentrum_variables.$global-padding;
					background-color: ezentrum_variables.$white;
					.ez_content_reduced_width {
						padding: ezentrum_variables.$global-padding;
						h1,
						h2,
						strong {
							font-weight: 400;
						}
						h1 {
							font-size: 1.2rem;
							text-align:center;
							text-transform: uppercase;
						}
						h2 {
							font-size: 0.85 * ezentrum_variables.$body-font-size;
							line-height: 1.04rem;
							margin-bottom: 0.845rem;
							text-transform: uppercase;
						}
						.Katalogtext1 {
							img {
								max-width:100%;
							}
							&:after {
								content:"";
								display:block;
								width:100%;
								height:1px;
								clear:both;
							}
						}
						.foto {
							img {
								max-width:100%;
							}
						}
						.winzer_details {
							margin-left:ezentrum_variables.$global-margin;
							width:200px;
							float:right;
							font-weight: 400;
							font-size:0.85 * ezentrum_variables.$body-font-size;
							th, td {
								padding:0.2 * ezentrum_variables.$global-padding 0.5 * ezentrum_variables.$global-padding;
							}
							th {
								font-weight: 400;
								background-color:lighten(ezentrum_variables.$gray,15);
							}
						}
					}
				}
				#kaufhistorie {
					background-color: ezentrum_variables.$white;
					.ez_content_reduced_width {
						margin-top: ezentrum_variables.$global-margin;
						padding: ezentrum_variables.$global-padding;
						h1 {
							margin-top: 1rem;
							font-size: 1.2rem;
							font-weight:400;
							text-transform: uppercase;
						}
						h2,
						h3 {
							margin-top: ezentrum_variables.$global-margin;
						}
						th:last-child,
						td:last-child {
							width:unset;
							margin:0;
							&:before {
								display:none;
							}
							.button {
								margin-top: ezentrum_variables.$global-margin;
								margin-left: -0.5 * ezentrum_variables.$global-margin;
								margin-right: -0.5 * ezentrum_variables.$global-margin;
								width:unset;
								@include ezentrum_mixins.for-size(tablet-portrait-up) {
									margin-right:-0.4rem;
									float:right;
									width:280px;
								}
							}
						}
						.button {
							font-weight:400;
							text-decoration: none;
						}
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							.text-right {
								padding-right: ezentrum_variables.$global-padding;
							}
						}
					}
					#date-picker {
						display: none;
						width: calc(100% - 50px);
						&::after {
							content: url(/resources/images/icons/calendar.svg);
							width: 24px;
							position: absolute;
							top: 55%;
							transform: translateY(-50%);
							right: 10px;
							pointer-events: none;
						}
					}
					#kaufhistorie_loadnext {
						background-color: ezentrum_variables.$white;
						border: 1px solid ezentrum_variables.$secondary-color;
					}
				}
				#article_filters {
					width: 100%;
					#ez_filterButton {
						max-width: 200px;
						margin: 0;
						height: 38px;
						background-color: ezentrum_variables.$white;
					}
					#ez_offcanvasFilter {
						width: 50%;
						@media screen and (max-width:992px) {
							width: 75%;
						}
						.offcanvas-header {
							.ez_closeFilter {
								width: 46px;
								height: 46px;
								margin: 0;
								&:hover {

								}
							}
						}
					}
				}
				#sub_navi {
					position: relative;
					margin-bottom: ezentrum_variables.$global-margin;
					margin-right: 0;
					margin-left: 0;
					border-radius: ezentrum_variables.$global-radius;
					background-color: rgba(255,255,255,0.6);
					> div {
						z-index: 20;
					}
					.headline {
						padding: 0.5 * ezentrum_variables.$global-padding auto;
						text-align: center;
						border-radius: ezentrum_variables.$global-radius ezentrum_variables.$global-radius 0 0;
						/*	background: ezentrum_variables.$listing-headline-background;*/
						h1, h2, h3 {
							margin: 0;
							padding: 0.5 * ezentrum_variables.$global-padding;
							font-size: 1.2rem;
							font-weight: 400;
						}
					}
					#sub_navi_1st {
						&.PU-Schaeume,
						&.PU-foams {
							min-height: 260px;
						}
					}
					.sub_navi_text {
						height: 100%;
						font-size: 1rem;
						font-weight:400;
						> span {
							display: block;
							margin: ezentrum_variables.$global-margin 0;
							height: calc(100% - ezentrum_variables.$global-margin);
							border-radius: ezentrum_variables.$global-radius;
							> a,
							> span.disabled,
							> span.subline {
								display: block;
								padding: 0.2rem ezentrum_variables.$global-padding;
								word-wrap: break-word;
								-moz-hyphens: auto;
								-o-hyphens: auto;
								-webkit-hyphens: auto;
								-ms-hyphens: auto;
								hyphens: auto;
								text-decoration: none;
								border-bottom: 1px solid ezentrum_variables.$primary-color;
								background-color: rgba(255,255,255,0.85);
							}
							> a:first-child,
							> span.subline:first-child,
							> span.disabled:first-child {
								border-radius: ezentrum_variables.$global-radius ezentrum_variables.$global-radius 0 0;
							}
							> a:last-child,
							> span.subline:last-child,
							> span.disabled:last-child {
								border-radius: 0 0 ezentrum_variables.$global-radius ezentrum_variables.$global-radius;
								border-bottom: none;
							}
							> a {
								color: ezentrum_variables.$body-font-color;
								&:hover {
									color: ezentrum_custom_variables.$red;
								}
								&.selected {
									color: ezentrum_custom_variables.$red;
									background-color: rgba(255,255,255,0.95);
								}
							}
							> span.subline {
								background-color: rgba(255,255,255,0.9);
								font-weight: 400;
							}
							> span.disabled {
								color: ezentrum_variables.$body-font-color;
							}
						}
					}
					#sub_navi_image {
						position: absolute;
						width: 100%;
						height: 100%;
						border-radius: ezentrum_variables.$global-radius;
						z-index: 1;
						> div {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							> div {
								position: absolute;
								width: 100%;
								height: 100%;
								border-radius: ezentrum_variables.$global-radius;
								background-size: cover;
								background-position: right top;
							}
						}
					}
				}
				.tab_public_element {
					border:1px solid lighten(ezentrum_variables.$gray,40);
					.ez_filter_selection {
						margin:0;
						padding: 0;
						.headline {
							position: relative;
							> svg {
								vertical-align:baseline;
							}
							.filter_headline {
								padding-left:ezentrum_variables.$global-padding;
								width: fit-content;
								font-size: 0.9rem;
								font-weight: 400;
							}
							.filter_hits {
								display:inline;
								font-size:0.8em;
								top: 0em;
								right: 0;
								position: relative;
								float: right;
								margin: auto;
								width: fit-content;
							}
						}
						&:after {
							height:1px;
						}
					}
					.filter .icon_plus,
					.filter .icon_minus {
						width: fit-content;
						height: fit-content;
						top: 50% !important;
						transform: translateY(-50%);
					}
				}
				.ez_filter_selection {
					margin: -1rem 0 1rem 0;
					padding-top: ezentrum_variables.$global-padding;
					padding-bottom: ezentrum_variables.$global-padding;
					border-bottom-right-radius:ezentrum_variables.$global-radius;
					border-bottom-left-radius:ezentrum_variables.$global-radius;
					background-color:rgba(255,255,255,0.5);
					height: 100%;
					@include ezentrum_mixins.for-size(desktop-up) {
						max-height: unset !important;
						.expand-area,
						.shrink-area {
							display:none !important;
						}
					}
					&:after {
						content:"";
						display:block;
						width:100%;
						height:2.5rem;
					}
					.expand-area .expand-button {
						margin: 0.5 * ezentrum_variables.$global-margin;
					}
					.shrink-area {
						margin:0;
						padding:0;
						height:1rem;
						.shrink-button {
							margin: 0.5 * ezentrum_variables.$global-margin;
							margin-top:0;
						}
					}
					.productselection {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-align-items(flex-start);
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(row,wrap);
						margin:-1rem 0 0 0;
						padding: 1rem 0 0 0;
						.filters {
							flex: 1 0 220px;
							margin: 0.5 * ezentrum_variables.$global-margin;
							padding-top: 0.5 * ezentrum_variables.$global-padding;
							padding-bottom: -0.5 * ezentrum_variables.$global-padding;
							background-color: rgba(255,255,255,0.5);
							span.filter_tablehead {
								display: block;
								clear: both;
								padding: 0.25rem;
								text-align: left;
								font-weight:400;
								line-height: 1.5;
								color: ezentrum_variables.$body-font-color;
								border-bottom: 1px solid ezentrum_variables.$primary-color;
								cursor: pointer;
							}
							.overlay {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background: rgba(255,255,255,0.5);
							}
							&.selector {
								.right {
									float: right;
								}
								.select10 ~ .filter_tablehead {
									display: block;
								}
								.select10 ~ .filter_tablehead_close {
									display: none;
								}
								.select10 ~ ul {
									display: none;
								}
								.select10:checked ~ .filter_tablehead {
									display: none;
								}
								.select10:checked ~ .filter_tablehead_close {
									display: block;
								}
								.select10:checked ~ ul {
									display: block;
								}
							}
							&.placeholder {
								margin: 0;
								height: 1px;
								background-color: transparent;
								cursor: auto;
							}
							.ez_filter {
								position:relative;
								text-align: left;
								background-color: transparent;
								&.excluded_filter {
									// display: none;
								}
								label {
									margin:0;
									padding:0;
									&:hover {
										color: ezentrum_custom_variables.$red;
									}
									input {
										position: absolute;
										opacity: 0;
										cursor: pointer;
										height: 0;
										width: 0;
									}
									.checkbox_label {
										padding:0;
										font-weight:400;
									}
									input:checked ~ .checkbox_label {
										color: ezentrum_custom_variables.$red;
									}
									.ez_icon {
										position: absolute;
										top: 15%;
										left: 0;
/* Breite / Höhe über $body-font-size kann zu Dezimalstellen führen, die in Safari fehlerhaft dargestellt werden
										width: ezentrum_variables.$body-font-size;
										height: ezentrum_variables.$body-font-size;
*/
										width: 15px;
										height: 15px;
										&.checked {
											display:none;
										}
									}
									input:checked ~ .ez_icon.unchecked {
										display:none;
									}
									input:checked ~ .ez_icon.checked {
										display:block;
										color: ezentrum_custom_variables.$red;
									}
									input:disabled { //can be used if not usable filters shall be shown
										~.checkbox_label { text-decoration: line-through; }
										~svg { display: none; }
										&.hover {
											~.checkbox_label { color: ezentrum_variables.$body-font-color; }
										}
									}
								}
							}
						}
						ul {
							list-style-type: none;
							margin: 0;
							padding: 0;
							li {
								padding: 0 0.5 * ezentrum_variables.$global-padding;
								clear: both;
								color: lighten(ezentrum_variables.$gray, 40);
								word-wrap: break-word;
								-moz-hyphens: auto;
								-o-hyphens: auto;
								-webkit-hyphens: auto;
								-ms-hyphens: auto;
								hyphens: auto;
								&.narrow {
									float: left;
									clear: right;
									width: 50%;
								}
								span.filter-label {
									display: inline-block;
									max-height: 18px;
									overflow: hidden;
								}
								&.disabled {
									span {
										> span {
											display: inline-block;
											width: calc(100% - 1.5rem);
										}
										.ez_icon {
											margin-top: 5px;
											margin-right: 0.5 * ezentrum_variables.$global-margin;
											width: 0.8 * ezentrum_variables.$body-font-size;
											height: 0.8 * ezentrum_variables.$body-font-size;
											vertical-align: top;
											color: lighten(ezentrum_variables.$body-font-color, 60);
										}
									}
								}
								a {
									display: block;
									text-decoration: none;
									font-weight:400;
									color: ezentrum_variables.$body-font-color;
									outline: none;
									> span {
										display: inline-block;
										width: calc(100% - 1.5rem);
									}
									.ez_icon {
										margin-top: 5px;
										margin-right: 0.5 * ezentrum_variables.$global-margin;
										width: 0.8 * ezentrum_variables.$body-font-size;
										height: 0.8 * ezentrum_variables.$body-font-size;
										vertical-align: top;
									}
									&:hover {
										text-decoration: none;
										color: ezentrum_custom_variables.$red;
									}
									&.active {
										font-weight: 400;
										color: ezentrum_custom_variables.$red;
									}
									&.col_black span::after,
									&.col_blue span::after,
									&.col_transp span::after,
									&.col_white span::after,
									&.col_yellow span::after,
									&.man_dpp span::after {
										display: inline-block;
										content: "";
										margin-top: 4px;
										width: ezentrum_variables.$body-font-size;
										height: ezentrum_variables.$body-font-size;
										border: 1px solid ezentrum_variables.$gray;
										float: right;
									}
									&.col_black span::after {background-color: rgba(0,0,0,1);}
									&.col_blue span::after {background-color: rgba(0,0,255,0.2);}
									&.col_transp span::after {background-color: rgba(255,255,255,0.1);}
									&.col_white span::after {background-color: rgba(255,255,255,1);}
									&.col_yellow span::after {background-color: rgba(255,255,0,0.2);}
									&.man_dpp span::after {background-image: url(/resources/images/icons/icon_man_dpp.png);}
								}
							}
						}
					}
				}
				a.allefilter {
					font-weight:400;
					text-decoration: none;
					color: #cc0000;
				}
				.productselection_wrapper {
					margin: -1rem 0 1rem 0;
					padding-top: ezentrum_variables.$global-padding;
					padding-bottom: ezentrum_variables.$global-padding;
					width:100%;
					border-bottom-right-radius:ezentrum_variables.$global-radius;
					border-bottom-left-radius:ezentrum_variables.$global-radius;
					background-color: ezentrum_variables.$white;
					@include ezentrum_mixins.for-size(desktop-up) {
						max-height: unset !important;
						.expand-area,
						.shrink-area {
							display:none !important;
						}
					}
					&:after {
						content:"";
						display:block;
						width:100%;
						height:2.5rem;
					}
					span#filter_toggle_brand {
						width:100%;
					}
					.expand-area .expand-button {
						margin: 0.5 * ezentrum_variables.$global-margin;
					}
					.shrink-area {
						margin:0;
						padding:0;
						height:1rem;
						.shrink-button {
							margin: 0.5 * ezentrum_variables.$global-margin;
							margin-top:0;
						}
					}
					.productselection {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-align-items(flex-start);
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(row,wrap);
						margin:-1rem 0 0 0;
						padding: 1rem 0 0 0;
						.filters {
							flex: 1 0 220px;
							margin: 0.5 * ezentrum_variables.$global-margin;
							padding-top: 0.5 * ezentrum_variables.$global-padding;
							padding-bottom: -0.5 * ezentrum_variables.$global-padding;
							background-color: rgba(255,255,255,0.5);
							span.filter_tablehead {
								display: block;
								clear: both;
								padding: 0.25rem;
								text-align: left;
								font-weight:400;
								line-height: 1.5;
								color: ezentrum_variables.$body-font-color;
								border-bottom: 1px solid ezentrum_variables.$primary-color;
								cursor: pointer;
							}
							.overlay {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background: rgba(255,255,255,0.5);
							}
							&.selector {
								.right {
									float: right;
								}
								.select10 ~ .filter_tablehead {
									display: block;
								}
								.select10 ~ .filter_tablehead_close {
									display: none;
								}
								.select10 ~ ul {
									display: none;
								}
								.select10:checked ~ .filter_tablehead {
									display: none;
								}
								.select10:checked ~ .filter_tablehead_close {
									display: block;
								}
								.select10:checked ~ ul {
									display: block;
								}
							}
							&.placeholder {
								margin: 0;
								height: 1px;
								background-color: transparent;
							}
							.ez_filter {
								position:relative;
								label {
									margin:0;
									padding:0;
									&:hover {
										color: ezentrum_custom_variables.$red;
									}
									input {
										position: absolute;
										opacity: 0;
										cursor: pointer;
										height: 0;
										width: 0;
									}
									.checkbox_label {
										padding:0;
										font-weight:400;
									}
									input:checked ~ .checkbox_label {
										color: ezentrum_custom_variables.$red;
									}
									.ez_icon {
										position: absolute;
										top: 3px;
										left: 0.5 * ezentrum_variables.$global-margin;
										width: 0.8 * ezentrum_variables.$body-font-size;
										height: 0.8 * ezentrum_variables.$body-font-size;
										&.checked {
											display:none;
										}
									}
									input:checked ~ .ez_icon.unchecked {
										display:none;
									}
									input:checked ~ .ez_icon.checked {
										display:block;
										color: ezentrum_custom_variables.$red;
									}
								}
							}
						}
						ul {
							list-style-type: none;
							margin: 0;
							padding: 0;
							li {
								padding: 0 0.5 * ezentrum_variables.$global-padding;
								clear: both;
								color: lighten(ezentrum_variables.$gray, 40);
								word-wrap: break-word;
								-moz-hyphens: auto;
								-o-hyphens: auto;
								-webkit-hyphens: auto;
								-ms-hyphens: auto;
								hyphens: auto;
								&.narrow {
									float: left;
									clear: right;
									width: 50%;
								}
								span.filter-label {
									display: inline-block;
									max-height: 18px;
									overflow: hidden;
								}
								&.disabled {
									span {
										> span {
											display: inline-block;
											width: calc(100% - 1.5rem);
										}
										.ez_icon {
											margin-top: 5px;
											margin-right: 0.5 * ezentrum_variables.$global-margin;
											width: 0.8 * ezentrum_variables.$body-font-size;
											height: 0.8 * ezentrum_variables.$body-font-size;
											vertical-align: top;
											color: lighten(ezentrum_variables.$body-font-color, 60);
										}
									}
								}
								a {
									display: block;
									text-decoration: none;
									font-weight:400;
									color: ezentrum_variables.$body-font-color;
									outline: none;
									> span {
										display: inline-block;
										width: calc(100% - 1.5rem);
									}
									.ez_icon {
										margin-top: 5px;
										margin-right: 0.5 * ezentrum_variables.$global-margin;
										width: 0.8 * ezentrum_variables.$body-font-size;
										height: 0.8 * ezentrum_variables.$body-font-size;
										vertical-align: top;
									}
									&:hover {
										text-decoration: none;
										color: ezentrum_custom_variables.$red;
									}
									&.active {
										font-weight:400;
										color: ezentrum_custom_variables.$red;
									}
									&.col_black span::after,
									&.col_blue span::after,
									&.col_transp span::after,
									&.col_white span::after,
									&.col_yellow span::after,
									&.man_dpp span::after {
										display: inline-block;
										content: "";
										margin-top: 4px;
										width: ezentrum_variables.$body-font-size;
										height: ezentrum_variables.$body-font-size;
										border: 1px solid ezentrum_variables.$gray;
										float: right;
									}
									&.col_black span::after {background-color: rgba(0,0,0,1);}
									&.col_blue span::after {background-color: rgba(0,0,255,0.2);}
									&.col_transp span::after {background-color: rgba(255,255,255,0.1);}
									&.col_white span::after {background-color: rgba(255,255,255,1);}
									&.col_yellow span::after {background-color: rgba(255,255,0,0.2);}
									&.man_dpp span::after {background-image: url(/resources/images/icons/icon_man_dpp.png);}
								}
							}
						}
					}
				}
				a.allefilter {
					font-weight:400;
					text-decoration: none;
					color: #cc0000;
				}
				#listing {
					#tabs {
						ul {
							list-style: none;
							li a {
								color: black;
							}
						}
						#navbar_filter.show {
							visibility: visible;
							transform: translateX(0);
							background-color: ezentrum_variables.$body-font-color;
							.tab_public_element {
								border: none;
								.callout {
									.content {
										display: flex;
										flex-wrap: wrap;
										border-top: none;
										background-color: #fdfbf0;
										border-radius: 0 0 .4rem .4rem;
										.ez_filterbox {
											&:not(.ez_filterbox.spacer) {
												flex: 1 0 200px;
												position: relative;
												margin: .4rem;
												border: 1px solid ezentrum_variables.$body-font-color;
												border-radius: .4rem;
												height: fit-content;
											}
											&.ez_filterbox.spacer { display: none; }
											.headline { border-bottom: 1px solid ezentrum_variables.$body-font-color; }
											.headline, .ez_filterlist { padding: .4rem; }
											label {
												padding: 0;
												margin: 0;
												&:hover { cursor: pointer; }
											}
											input { display: none; }
											input:checked~.fa-square,input~.fa-check-square,input~.fa-times { display: none; }
											input:checked~.fa-check-square,input~.fa-square, input:checked~.fa-times  { display: block; }
											input~.fa-square,input:checked~.fa-check-square,input:checked~.fa-times {
												font-weight: bold;
												font-size: x-large;
											}
											input~.fa-square { color: #999;}
											input:checked~.fa-check-square { color: green;}
											input:checked~.fa-times { color: #c00; }
											input~.checkbox_label { color:ezentrum_variables.$body-font-color; text-align: center; }
											.ez_icon {
												position: absolute;
												top: 3px;
												left: 0.4rem;
												width: 0.8rem;
												height: 0.8rem;
											}
										}
									}
									.row.headline {
										.btn-filter {
											background-color: transparent;
											border-color: #fff;
											border-radius: .4rem .4rem 0 0;
											color: #fff;
											&:hover {
												background-color: #fcfaef;
												color: ezentrum_variables.$body-font-color;
											}
											.filtericon-left {
												height: 20px;
												width: 20px;
											}
										}
										&[data-ez-collapse="true"] {
											.btn-filter {
												background-color: #d6ad33;
												border: none;
												&:hover { background-color: #fcfaef; }
											}
										}
										&[data-ez-collapse="false"] { .btn-filter { border-radius: .4rem; } }
									}
								}
								.btn-close {
									width: 39px;
									float: right;
								}
							}
						}
						#navbar_filter { // the filters are not shown
							visibility: hidden;
							border-radius: 0;
							display: block;
							position: fixed;
							top: 0;
							left: 0;
							height: 100%;
							z-index: 1200;
							width: 55%;
							overflow-y: auto;
							overflow-x: hidden;
							transition: visibility .4s ease-in-out,transform .4s ease-in-out;
							transform: translateX(-100%);
						}
						#filters_box {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							#filter_box_offcanvas {
								width: 90%;
								@media screen and (min-width: 1001px) {
									width: 1000px;
								}
								.offcanvas-header {
									.btn-close {
										width: 33px;
									}
								}
							}
						}
						#tab-shop {
							margin: 0 -(.8rem);
						}
					}
				}
				#static {
					padding: 2 * ezentrum_variables.$global-padding;
					background-color: ezentrum_variables.$white;
					.image_container {
						margin-right: 2 * ezentrum_variables.$global-margin;
						margin-bottom: 2 * ezentrum_variables.$global-margin;
						display:inline-block;
						float:left;
						width:calc(100% + (4 * #{ezentrum_variables.$global-padding}));
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							width:50%;
						}
						img {
							width: 100%;
						}
						div.small-text {
							padding-left: ezentrum_variables.$global-padding;
						}
					}
					h2 {
						font-size:ezentrum_variables.$body-font-size;
						font-weight:700;
					}
					h3 {
						font-size:0.9 * ezentrum_variables.$body-font-size;
						font-weight:700;
					}
					h4 {
						font-size:0.9 * ezentrum_variables.$body-font-size;
						font-weight:400;
					}
					.versand-table {
						tr:nth-child(even) {
							background-color:lighten(ezentrum_variables.$gray,15);
						}
						.versand-icon {
							max-width:80px;
						}
					}
				}
				.ez_passwort_toggle_container {
					position: relative;
					margin-bottom: 0.8rem;
					#tfPasswort {
						margin: 0;
					}
					.status {
						bottom: 0;
						right: 6px;
						position: absolute;
						label {
							margin: 0;
							svg {
								position: inherit;
								width: 30px;
								height: 30px;
							}
						}
					}
				}
			}
			#erzeuger_filter_box_offcanvas.offcanvas {
				.offcanvas-header {
					background-color:ezentrum_custom_variables.$light-yellow;
					padding-left: 19px;
					padding-right: 25px;
					display: block;
					position: relative;
					.offcanvas-title {
						color: ezentrum_variables.$body-font-color;
						font-size: 1.2rem;
						font-weight: 500;
						text-transform: uppercase;
					}
					.btn-close {
						position: absolute;
						right: 30px;
						top: 11px;
						width: 33px;
						opacity: 1;
						background-color: transparent !important;
					}
				}
				.offcanvas-body {
					#ez_sidenavi_container {
						display:flex;
						width:100%;
						#ez_sidenavi.ez_level1 {
							border-bottom:none;
							> li > label {
								border-top:none;
							}
						}
					}
				}
				.offcanvas-footer {
					align-self: center;
					margin-top:ezentrum_variables.$global-margin;
					min-width:80%;
					.ez_button_white {
						background-color:white;
						border:1px solid ezentrum_custom_variables.$light-yellow;
					}
				}
			}
		}
	}
	#ez_footer_fullwidth {
		margin-top: 2rem;
		#ez_footer1_fullwidth {
			#ez_footer1_wrapper {
				padding-top:30px;
				border-top: solid 0.063rem #D7B144;
				.ez_footer1_section {
					display:block;
					width:100%;
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(row,nowrap);
					}
					.ez_footer1_item {
						max-width: 480px;
						font-weight: 300;
						font-size:14px;
						img {
							margin-bottom:4px;
						}
						ul {
							list-style-type:none;
							padding-left:16px;
							li:before {
								content:"-";
								margin-left: -16px;
								margin-right: 10px;
							}
						}
						a:hover {
							text-decoration:none;
						}
						h3 {
							text-transform:uppercase;
							color:#d5d5d5;
						}
						.socialbuttons {
							margin-top:8px;
							width:100%;
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,nowrap);
							.socialbutton {
								flex:1 0 auto;
								margin:0 4px;
								padding:3px 4px;
								border-radius:5px;
								text-align:center;
								font-size:30px;
								background:#9b9b9b;
								color:#1a1a1a;
								&:first-child {margin-left:0;}
								&:last-child {margin-right:0;}
							}
						}
						.icon {
							margin-bottom:10px;
							max-width:180px;
						}
					}
				}
			}
		}
		#ez_footer2_fullwidth {
			font-weight: 300;
			font-size:12px;
			#ez_footer2_wrapper {
				margin-bottom:0;
				.ez_footer2_section {
					margin:ezentrum_variables.$global-margin 0;
				}
			}
		}
	}
}

#to_top {
	background: white;
	border: solid 0.065rem #D7B144;
	bottom: 20px;
	box-shadow: 0 0.195rem 0.195rem rgb(0 0 0 / 20%);
	color: #001D30;
	cursor: pointer;
	font-size: 1rem;
	line-height: 1rem;
	height: 60px;
	padding-top: 4px;
	position: fixed;
	right: 20px;
	text-align: center;
	width: 42px;
	z-index: 10000;
	display: block;
	text-decoration: none;
	img {
		max-width: 100%;
		max-height: 100%;
		height: auto;
	}
	.scroller_text {
		font-size: 0.75rem;
		line-height: 0.75rem;
	}
}

// ##### start: responsive-tables ##### //
table.responsive-table-1 {
	width:100%;
	border-collapse:collapse;
	tr {margin:0;padding:0;}
	td, th {
		padding:0.2rem 0.4rem;
		border: 1px solid grey;
		.text-center {text-align:center;}
		.text-right {text-align:right;}
	}
	th {font-weight: 400;}
}
@media screen and (max-width:39.9375rem) {
	table.responsive-table-1 {
		thead {display:none;}
		tr {border-top:1px solid grey;}
		tr:last-child {border-bottom:1px solid grey;}
		td {
			float: left;
			width: 100%;
			text-align:left;
			border-top:none;
			border-bottom:none;
		}
		td::before {
			float: left;
			margin:-0.2rem 0.4rem 0 -0.4rem;
			padding:0.2rem 0.4rem;
			width: calc(100% + 0.8rem);
			content:attr(data-label);
			font-weight: 400;
			word-wrap: break-word;
			hyphens: auto;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			border:none;
		}
	}
}

table.responsive-table-2 {
	width:100%;
	border-collapse:collapse;
	border-bottom:1px solid lighten(ezentrum_variables.$gray,10);
	tr {
		margin:0;
		padding:0;
		&:nth-child(odd) {
			background-color:ezentrum_variables.$white;
		}
		&:nth-child(even) {
			background-color:lighten(ezentrum_variables.$gray,15);
		}
	}
	td, th {
		padding:0.2rem 0.4rem;
		border:none;
		&.text-center,
		.text-center {
			text-align:center;
		}
		&.text-right,
		.text-right {
			text-align:right;
		}
	}
	th {
		font-weight: 400;
		background-color:lighten(ezentrum_variables.$gray,15);
	}

}
@media screen and (max-width: 63.9375rem) {
	table.responsive-table-2 {
		thead {
			display:none;
		}
		tr {
			border-top:1px solid grey;
			&:last-child {
				border-bottom:1px solid grey;
			}
		}
		td {
			float: left;
			width: 100% !important;
			height:unset !important;
			text-align:left!important;
			border-top:none;
			border-bottom:none;
			&::before {
				float:left;
				margin:-0.4rem .8rem 0 -0.4rem;
				padding:0.4rem;
				width:25%;
				content:attr(data-label);
				font-weight: 400;
				word-wrap: break-word;
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
				border-right: 1px solid grey;
				border-bottom: 1px solid grey;
			}
			&:last-child::before {
				border-bottom:none;
			}
		}
	}
}
@media screen and (max-width:39.9375rem) {
	table.responsive-table-2 {
		td::before {
			float: left;
			margin:-0.2rem 0.4rem 0 -0.4rem;
			width: calc(100% + 0.8rem);
			border:none;
		}
	}
}

table.responsive-table-3 {
	width:100%;
	border-collapse:collapse;
	tr {margin:0;padding:0;}
	td, th {
		padding:0.2rem 0.4rem;
		border: 1px solid grey;
		.text-center {text-align:center;}
		.text-right {text-align:right;}
	}
	th {
		font-weight: 400;
	}
}
@media screen and (max-width:63.9375rem) {
	table.responsive-table-3 {
		thead {display:none;}
		tr {border-top:1px solid grey;}
		tr:last-child {border-bottom:1px solid grey;}
		td {
			float: left;
			width: 100%;
			text-align:left;
			border-top:none;
			border-bottom:none;
		}
		td::before {
			float: left;
			margin:-0.2rem 0.4rem 0 -0.4rem;
			padding:0.2rem 0.4rem;
			width: calc(100% + 0.8rem);
			content:attr(data-label);
			font-weight: 400;
			word-wrap: break-word;
			hyphens: auto;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			border:none;
		}
	}
}
// ##### stop: responsive-tables ##### //

.modal {
	.btn-close {
		background-color: transparent !important;
	}
}

.livesuche_search_tabs{
	display: none !important;
}

span.icon_plus {
	display: block;
    top: 0rem;
    right: 0rem;
}
.buyerlist {
	background-color: ezentrum_variables.$white;
	.buyer_tablehead,
	.buyer_tablerow {
		@include ezentrum_mixins.display-flex;
		@include ezentrum_mixins.flex-justify-content(space-between);
		> div {
			padding:ezentrum_variables.$global-padding;
		}
	}
	.buyer_tablehead {
		@include ezentrum_mixins.flex-flow(row,nowrap);
		font-weight:400;
		background-color:lighten(ezentrum_variables.$gray,15);
		> div {
			&.customernumber {
				flex: 0 0 30%;
				word-wrap:normal;
				hyphens: auto;
			}
			&.customername {
				flex: 0 0 70%;
			}
			&.customerselect {
				display:none;
			}
		}
		@include ezentrum_mixins.for-size(tablet-portrait-up) {
			> div {
				&.customernumber {
					flex: 0 0 15%;
				}
				&.customername {
					flex: 0 0 40%;
				}
				&.customerselect {
					display:block;
					flex: 0 0 45%;
				}
			}
		}
	}
	.buyer_tablerow {
		@include ezentrum_mixins.flex-flow(row,wrap);
		> div {
			&.customernumber {
				flex: 0 0 30%;
				word-wrap:normal;
				hyphens: auto;
			}
			&.customername {
				flex: 0 0 70%;
			}
			&.customerselect {
				flex: 0 0 100%;
			}
		}
		@include ezentrum_mixins.for-size(tablet-portrait-up) {
			@include ezentrum_mixins.flex-flow(row,nowrap);
			> div {
				&.customernumber {
					flex: 0 0 15%;
				}
				&.customername {
					flex: 0 0 40%;
				}
				&.customerselect {
					flex: 0 0 45%;
				}
			}
		}
		&:nth-child(odd) {
			background-color:lighten(ezentrum_variables.$gray,15);
		}
		.button {
			margin:0;
			float:right;
			max-width:280px;
		}
		&.selected {
			font-weight:400;
			color:ezentrum_custom_variables.$red;
		}
	}
}

.listing\.htm {
	#ez_category_filter, .search_results_headline { display: none; }
	#ez_shortcut_main_filter_button {
		width: 100% !important;
		@media (min-width: 768px){
			width: 33.33333333% !important;
		}
	}
}

