@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			.ez_banner_block {
				.ez_banner_image {
					a {
						display:block;
						margin:0;
						width: 100%;
						height: 100%;
					}
					&.sale {
						background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712735677/pinard/ywpdkyeqljtldnbtzxqu.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712735677/pinard/ywpdkyeqljtldnbtzxqu.webp);
						}
					}
					&.cdc {
						background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728916/pinard/gdfgmoopfnngo5fjlnzs.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728916/pinard/gdfgmoopfnngo5fjlnzs.webp);
						}
					}
					&.schatzkammer {
						background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728917/pinard/gzjwhfq5h1tb8hd4txlg.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728917/pinard/gzjwhfq5h1tb8hd4txlg.webp);
						}
					}
					&.ggew {
						background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728917/pinard/fw28nak98qtcw89n6os3.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728917/pinard/fw28nak98qtcw89n6os3.webp);
						}
					}
					&.subskriptionen {
						background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728917/pinard/agv2yswbxmhc8ctij2mt.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(https://ezentrum-res.cloudinary.com/image/upload/v1712728917/pinard/agv2yswbxmhc8ctij2mt.webp);
						}
					}
					&.winzer {
						background-image:url(/resources/images/winzer/erzeuger.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(/resources/images/winzer/erzeuger.webp);
						}
					}
					&.winzer_neu {
						background-image:url(/resources/images/winzer/neue-weingueter.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(/resources/images/winzer/neue-weingueter.webp);
						}
					}
					&.newsletter {
						background-image:url(/resources/images/startseite/teaser/newsletter-anmelden-home-mob.jpg);
						@supports (background-image: url('logo.webp')) {
							background-image:url(/resources/images/startseite/teaser/newsletter-anmelden-home-mob.webp);
						}
					}
				}
			}
			#ez_content {
				.ez_banner_columns {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					@include ezentrum_mixins.flex-flow(column,nowrap);
					.ez_banner_block {
						flex:unset;
					}
					@include ezentrum_mixins.for-size(tablet-portrait-up) {
						@include ezentrum_mixins.flex-flow(row,wrap);
						.ez_banner_block {
							flex:0 1 50%;
						}
					}
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						.ez_banner_block {
							flex:0 1 48%;
						}
					}
				}
				.home--container {
					margin: ezentrum_variables.$global-margin 0 0 0;
					.home .article > h1 {
						display: none;
					}
					.home .article {
						padding: 0;
						margin: 0 auto;
						background: none;
						max-width: none;
					}
					.home--headline {
						min-height: 4.355rem;
						text-align: center;
						background: #fff;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: center;
						color: #001D31;
					}
					.home--small, .home--large {
						margin-bottom: ezentrum_variables.$global-margin;
						text-align: left;
					}
					.home--small .home--headline, .home--large .home--headline {
						font-weight: 700;
						font-size: 1.105rem;
						color: #790C1B;
						text-align: left;
						padding: 1.3rem 1.95rem 0.65rem;
					}
					.home--small .home--headline strong, .home--large .home--headline strong {
						width: 100%;
						display: block;
						font-weight: 700;
						color: #001D31;
						text-align: left;
						margin-bottom: 1.3rem;
					}
					.home--hero {
						margin-bottom: 0;
					}
					.home--hero .home--headline {
						color: #001D31;
						font-weight: 700;
						font-size: 1.3rem;
						text-align: center;
						padding: 0.65rem 1.3rem 0.65rem;
					}
					.home img {
						display: block;
						width:100%;
					}
					.home .dummy {
						margin: 0 0 2.6rem;
						text-align: center;
						line-height: 5.2rem;
						background: #fff;
					}
					.home--highlight {
						padding: 1.95rem 1.95rem;
						background: #001D31;
						color: #D7B144;
						font-size: 0.91rem;
						text-decoration:none;
						line-height: 1.3rem;
						font-weight: 400;
					}
					.home--highlight a strong {
						background: #001D31;
						color: #D7B144;
						font-size: 0.91rem;
						text-decoration:none;
						line-height: 1.3rem;
						font-weight: 700;
					}
					@media all and (min-width: 49.92rem) {
						.home--highlight {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							}
					}
					.home--highlight strong {
						text-transform: uppercase;
					}
					.home--highlight .highlight--box {
						padding: 0.65rem 0;
					}
					.home--highlight .box--w100 {
						width: 100%;
					}
					@media all and (min-width: 49.92rem) {
						.home--highlight .box--w33 {
							width: 33.3333%;
							}
					}
					@media all and (min-width: 49.92rem) {
						.home--highlight .box--w75 {
							width: 75%;
							}
					}
					@media all and (min-width: 49.92rem) {
						.home--highlight .box--w50 {
							width: 50%;
						}
					}
					@media all and (min-width: 49.92rem) {
						.home--highlight .box--w25 {
							width: 25%;
						}
					}
					.home--cta {
						margin-bottom: ezentrum_variables.$global-margin;
						background: #fff;
						padding: 1.95rem 1.95rem;
						font-size: 0.975rem;
						font-weight: bold;
					}
					@media all and (min-width: 49.92rem) {
						.home--cta {
							display: flex;
							flex-wrap: nowrap;
						}
					}
					.home--cta .home-cta--text {
						justify-self: flex-start;
						margin-right: auto;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-bottom: 2.6rem;
						position: relative;
					}
					@media all and (max-width: 27.62435rem) {
						.home--cta .home-cta--text {
							margin-bottom: 5.2rem;
						}
					}
					@media all and (min-width: 49.92rem) {
						.home--cta .home-cta--text {
							padding-right: 1.95rem;
							margin-bottom: 0;
						}
					}
					@media all and (max-width: 27.62435rem) {
						.home--cta .home-cta--text--inner {
							max-height: 100px;
							text-overflow: ellipsis;
							overflow: hidden;
							position: relative;
						}
						.home--cta .home-cta--text--inner::after {
							display: block;
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							height: 50px;
							width: 100%;
							background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
						}
					}
					@media all and (max-width: 27.62435rem) {
						.home--cta .home-cta--text.open .home-cta--text--inner {
							max-height: none;
						}
						.home--cta .home-cta--text.open .home-cta--text--inner::after {
							display: none;
						}
					}
					@media all and (max-width: 27.62435rem) {
						.home--cta .home-cta--text::after {
							display: flex;
							content: "Mehr Informationen >";
							text-align: center;
							position: absolute;
							bottom: -35px;
							left: 0;
							width: 100%;
							height: 30px;
							align-items: center;
							justify-content: center;
						}
					}
					@media all and (max-width: 27.62435rem) {
						.home--cta .home-cta--text.open::after {
							display: none;
						}
					}
					.home--cta .home-cta--linked-image {
						justify-self: flex-end;
						flex: 0 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-bottom: 2.6rem;
						img {
							max-width:100%;
						}
					}
					@media all and (min-width: 39rem) {
						.home--cta .home-cta--linked-image {
							margin-bottom: 0;
						}
					}
					@media all and (min-width: 49.92rem) {
						.home--box {
							display: flex;
							justify-content: space-between;
							margin-bottom: 2.6rem;
						}
					}
					.home--box .home-box--banner {
						margin-bottom: 2.6rem;
					}
					@media all and (min-width: 49.92rem) {
						.home--box .home-box--banner {
							margin: 0 10px;
						}
					}
					@media all and (min-width: 83.2rem) {
						.home--box .home-box--banner {
							margin: 0;
						}
					}
					.home--container {
						margin: 0 auto;
						overflow: hidden;
					}
					.home--container a {
						text-decoration: none !important;
					}
					@media all and (min-width: 49.92rem) {
						.home--container {
							max-width: none;
						}
					}
					.home--products .product {
						margin-bottom: 2.6rem;
					}
					.article {
						width: 100%;
						margin: 0 auto;
						overflow: auto;
						padding: 1.105rem 1.3rem;
						color: #001D31;
						background-color: white;
						font-weight: 300;
					}
					@media all and (min-width: 27.625rem) {
						.article {
							max-width: 18.2rem;
						}
					}
					@media all and (min-width: 72rem) {
						.article {
							max-width: 76.7rem;
						}
					}
					@media all and (min-width: 768px) {
						.article {
							width: 100%;
							max-width: 37.7rem;
						}
					}
					@media all and (min-width: 55rem) {
						.article {
							max-width: 76.7rem;
							padding-top: 2.275rem;
						}
					}
					.article ol, .article ul {
						margin-left: 30px;
					}
					.article ol li, .article ul li {
						margin-bottom: 10px;
					}
					.article ol {
						list-style-type: decimal;
					}
					.article ul {
						list-style-type: disc;
					}
					.article strong {
						font-weight: 500;
					}
					.article p {
						max-width: 58.5rem;
						padding-bottom: 0.9375rem;
					}
					.article p strong {
						font-weight: bold;
					}
					.article a {
						text-decoration: underline !important;
						color: inherit;
						cursor: pointer;
					}
					.article a:hover {
						color: #790C1B;
					}
					.article a.button:hover {
						color: #CC9800;
					}
					.article h1, .article h2, .article h3, .article h4, .article h5, .article h6 {
						font-size: 0.91rem;
						line-height: 1.04rem;
						font-weight: 500;
						margin-bottom: 0.845rem;
						text-transform: uppercase;
						max-width: 58.5rem;
					}
					.article h2 {
						margin-top: 2rem;
						font-size: 1.17rem;
						color: #D7B144;
					}
					.article h3 {
						margin-top: 2rem;
						color: #790C1B;
					}
					.article #cms .flex-container {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
					}
					.article #cms .flex-item {
						margin-right: 2rem;
					}
					.article th {
						padding: 0.5rem 2rem;
						text-align: left;
						background-color: #D7B144;
					}
					.article td {
						padding: 0.5rem 2rem;
						text-align: center;
						border: 1px solid #D7B144;
					}
					.article div.icon {
						float: left;
						width: auto;
						margin-right: 2rem;
						margin-bottom: 1rem;
					}
					.article div.icon img {
						height: 4rem;
						width: auto;
					}
					.article div.clearfix {
						display: block;
						clear: both;
					}
					.article .foto.half {
						width: 100%;
						max-width: 606px;
					}
					.article .foto.half.left {
						float: left;
						margin-right: 2rem;
					}
					.article .foto.half.left img {
						width: 100%;
						height: auto;
					}
					.article .foto.half.right {
						float: left;
						margin-right: 2rem;
					}
					.article .foto.half.right img {
						width: 100%;
						height: auto;
					}
					.article .foto .legende {
						margin-top: -0.2rem;
						text-align: center;
						font-size: 0.8rem;
						opacity: 0.6;
					}
					.article > h1 + .foto.half.left {
						margin: -50px 2rem .5rem -20px;
					}
					.article > h1 + .foto.half.left + h1 {
						margin-top: -0.845rem;
					}
					.article #cms #news .item {
						clear: both;
						padding-top: 0.4rem;
					}
					.article #cms #news h2 {
						margin-top: 0;
						padding-top: 0;
					}
					.article #cms #news .text {
						margin-left: 12rem;
						padding: 0;
					}
					.article #cms #news .fotolinks img {
						width: 10rem;
						height: auto;
						float: left;
					}
					.article #cms li {
						padding-top: 1rem;
					}
					.article #cms button {
						width: 24rem;
						height: 3rem;
						margin: 3rem 0;
						padding: 0;
						text-transform: uppercase;
						background-color: #CC9800;
					}
					.article #cms button a {
						display: block;
						padding: 1rem;
						height: 100%;
					}
					.article #cms button :hover {
						color: #CC9800;
						background-color: #002e4d;
					}
				}
				
				.ez_productlist {
					margin-top:0;
					margin-bottom: ezentrum_variables.$global-margin;
				}
			}
		}
	}
}
