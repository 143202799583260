/* _ezentrum_customize_productlist.scss for custom styling on all productlist pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

$light-yellow: rgb(218,193,115);

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				#ez_main_filter_button {
					background-color:$light-yellow;
					&:hover {
						background-color:ezentrum_variables.$body-font-color;
						.ez_icon {
							fill:ezentrum_variables.$primary-color;
						}
					}
				}
				.ez_pageselect,
				.ez_pagesort {
					float:right;
					a, button {
						padding: 0;
						text-decoration: none;
						color: black;
						transition: background-color .3s;
						border: 1px solid #ddd;
						float: left;
					}
					button.prev,
					button.next {
						width:2.4rem;
						background-color:$light-yellow;
						color:ezentrum_variables.$body-font-color;
						border:1px solid #919191;
						&:hover {
							background-color:ezentrum_variables.$body-font-color;
							.ez_icon {
								color:ezentrum_variables.$primary-color;
							}
						}
					}
					button.prev {
						border-right:none;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}
					button.next {
						border-left:none;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
				.ez_pageselect {
					span {
						border-radius:0;
					}
				}
				#productfilters-dropdown {
					display:none;
				}
				#productfilters-dropdown:checked ~ .ez_productlist_filters #productfilters #productfilters-items {
					display:block;
				}
				#productfilters-dropdown:checked ~ .ez_productfilters_headline .ez_productfilters_element:first-child {
					// border-bottom:none;
				}
				.ez_productfilters_headline {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					@include ezentrum_mixins.flex-flow(row,nowrap);
					.ez_productfilters_element {
						flex:1 0 auto;
						background-color:transparent;
						&:first-child {
						}
						label {
							position:relative;
							margin:0;
							padding:0.5 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
							font-weight:400;
							svg.ez_icon {
								position:absolute;
								top:calc(0.9 * #{ezentrum_variables.$global-padding});
								right:ezentrum_variables.$global-padding;
								color:ezentrum_variables.$body-font-color;
							}
						}
						.ez_select {
							> span {
								margin:0;
								border:none;
								select {
									height: 100%;
									line-height: ezentrum_variables.$body-line-height;
									text-align: center;
								}
							}
							svg.ez_select_icon {
								position:absolute;
								top: 50%;
								transform: translateY(-50%);
								right:ezentrum_variables.$global-padding;
								color:ezentrum_variables.$body-font-color;
							}
						}
						.ez_productlist_sort {
							.ez_pageselect {
								button.prev,
								button.next {
									margin:0;
									width:37px;
									height:37px;
									border:none;
									color:#000;
									background-color:lighten(ezentrum_variables.$primary-color,32);
								}
								span {
									width:calc(100% - 72px);
								}
							}
						}
					}
				}
				.ez_productlist_filters {
					#productfilters {
						.ez_active_filters {
							margin: ezentrum_variables.$global-margin 0;
							padding:0;
							.productfilter-label {
								margin: ezentrum_variables.$global-margin 0.5 * ezentrum_variables.$global-margin;
								font-size:0.85 * ezentrum_variables.$body-font-size;
								background-color:lighten(ezentrum_variables.$primary-color,32);
								a {
									padding:0.5 * ezentrum_variables.$global-padding;
									color: ezentrum_variables.$body-font-color;
									text-decoration:none;
								}
							}
						}
						#productfilters-items {
							display:none;
							background-color: ezentrum_variables.$white;
							border-right:1px solid ezentrum_variables.$primary-color;
							border-bottom:1px solid ezentrum_variables.$primary-color;
							border-left:1px solid ezentrum_variables.$primary-color;
							.productfilters-item {
								padding-top: ezentrum_variables.$global-padding;
								padding-left: ezentrum_variables.$global-padding;
								ul {
									margin:0;
									padding: ezentrum_variables.$global-padding;
									width:100%;
									list-style-type:none;
									li {
										min-height: ezentrum_variables.$body-line-height;
										a,
										span {
											display:block;
											font-size: 0.85 * ezentrum_variables.$body-font-size;
											text-decoration:none;
											color: ezentrum_variables.$body-font-color;
											svg {
												float:left;
												margin-top: 5px;
												margin-right: 5px;
												width:12px;
												height:12px;
											}
										}
										&.disabled {
											a,
											span {
												color: ezentrum_variables.$gray;
											}
										}
									}
								}
							}
						}
					}
				}
				.ez_productlist_headline {
					h1 {
						margin-top:0.5 * ezentrum_variables.$global-margin;
						font-size:1rem;
						font-weight:400;
						text-transform: uppercase;
					}
				}
				.ez_productlist_description {
				}
				.ez_productlist_sort {
				}
				.ez_productlist {
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(300px, auto));
					flex-flow:unset;
					justify-content:unset;
					margin-top: ezentrum_variables.$global-margin;
					width:calc(100% + (2 * #{ezentrum_variables.$global-margin}));
					.ez_product_wrapper {
						flex: unset;
						padding-top: ezentrum_variables.$global-padding;
						width:unset;
						background-color:white;
						border:none;
						.ez_product {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,nowrap);
							margin-bottom: -1 * ezentrum_variables.$global-margin;
							.ez_productimage {
								height:280px;
							}
							.ez_product_left,
							.ez_product_right {
								font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
								line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
							}
							.ez_product_left {
								flex:1 0 120px;
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									flex:1 0 120px;
								}
								a {
									display:block;
									margin:0;
									padding:0;
								}
								.ez_productimage {
									position:relative;
									margin:0;
									padding:0;
									width:100%;
									height:280px;
									picture,
									img {
										width:unset;
										max-height:280px;
									}
								}
							}
							.ez_product_right {
								flex:2 0 160px;
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									flex:2 0 160px;
								}
								.ez_productname {
									height: 6rem;
									h4 {
										margin:0;
										padding:0;
										font-size: ezentrum_variables.$body-font-size;
										font-weight:700;
										color:ezentrum_variables.$body-font-color;
										hyphens:none;
										overflow:hidden;
										a {
											text-decoration:none;
											.erzeuger {
												font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
												line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
												font-weight: 400;
											}
										}
									}
								}
								.siegel_wrapper {
									@include ezentrum_mixins.display-flex;
									@include ezentrum_mixins.flex-justify-content(flex-start);
									@include ezentrum_mixins.flex-flow(row,nowrap);
									margin-left:-3px;
									height:65px;
									.siegel_element {
										min-width:42px;
										max-height:80px;
										text-align:center;
										img {
											max-height:36px;
										}
										p {
											font-size: calc(0.5 * #{ezentrum_variables.$body-font-size});
										}
									}
								}
								.ez_productinfo {
									min-height:140px;
									font-size: calc(0.75 * #{ezentrum_variables.$body-font-size});
									>span {
										display:block;
										margin-bottom: 0.5 * ezentrum_variables.$global-margin;
										width: 100%;
										text-align:unset;
										line-height: calc(0.5 * #{ezentrum_variables.$body-line-height});
										&:after {
											content:"";
											display:block;
											clear:both;
										}
									}
									.info_icon {
										margin-right:4px;
										float:left;
										width:0.8 * ezentrum_variables.$body-font-size;
										height:0.8 * ezentrum_variables.$body-font-size;
										background-color:transparent;
										background-size:contain;
										background-repeat:no-repeat;
										&.standard {
											border-radius: 50%;
											background-color:ezentrum_variables.$gray;
										}
									}
									.info_text {
										display:inline-block;
										width:calc(100% - 20px);
										vertical-align:top;
									}
									.ez_availability {
										position: relative;
										top: unset;
										right: unset;
										float:left;
										.ez_availabilitytext {
											padding-left:0.2 * ezentrum_variables.$global-padding;
											line-height:1rem;
											color: ezentrum_variables.$body-font-color;
										}
										.ez_availabilityicon {
											margin-top:2px;
											margin-right:2px;
											float:left;
											width:0.8 * ezentrum_variables.$body-font-size;
											height:0.8 * ezentrum_variables.$body-font-size;
										}
										&.avail_reservierbar .ez_availabilityicon {
											background-color:#ee8b30;
										}
										&.avail_subskription .ez_availabilityicon {
											background-color:#294b64;
										}
									}
									&:after {
										content:"";
										display:block;
										clear:both;
									}
								}
								.ez_productprice {
									padding-top: ezentrum_variables.$global-padding;
									height:35px;
									line-height: 0.94rem;
									text-align:left;
									span:not(:first-child) {
										font-size: calc(0.75 * #{ezentrum_variables.$body-font-size});
									}
									.ez_price_container {
										letter-spacing: 0px;
									}
									.ez_normalpreis, .ez_sonderpreis {
										font-size: 1.2 * ezentrum_variables.$body-font-size;
										font-weight: 700;
									}
									.ez_normalpreis {
									}
									.ez_sonderpreis {
										color: ezentrum_custom_variables.$red;
									}
									.ez_grundpreis {
										font-weight:700;
										color: ezentrum_variables.$body-font-color;
									}
									.ez_availability {
										position: relative;
										margin: 0;
										top: 0;
										right:0;
									}
								}
							}
						}
						.ez_product_bottom {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(flex-end);
							@include ezentrum_mixins.flex-flow(column,nowrap);
							padding: 0.5 * ezentrum_variables.$global-padding;
							width:100%;
							min-height:86px;
							font-size:calc(0.6 * #{ezentrum_variables.$body-font-size});
							line-height:0.8rem;
							text-align:center;
							.ez_productnumber {}
							.link {
								margin: -0.5 * ezentrum_variables.$global-margin;
								margin-top: 0.5 * ezentrum_variables.$global-margin;
								a.button {
									margin:0;
								}
							}
						}
						&.dummy {
							height:1px;
							&:hover {
								background:transparent;
							}
						}
					}
				}
			}
		}
	}
}
.listing\.htm {
	#search_query_string, #tab-shop, #next_button_container { display: none; }
}