﻿/* _ezentrum_custom_variables.scss */

/* Colors */
$red:#790C1B;
$light-yellow: rgb(218,193,115);
$light-gray: #c1cdd7;

$color-ez_basket_hr:#cc9800;
$color-table-row-small-text: #5D798B;

/* Font Size */
$text-small: #c1cdd7;

/* Border Color*/
$border-color-button-refresh: #808080;


/* BG-Color */
$bg-color-neutral: #fff;
$bg-color-button-weiter-einkaufen: #e5e8ea;

/* ###### Global BG Scope ###### */
$layout_element_bgcolor_1: #fff;
