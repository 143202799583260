@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				#listing {
					#tabs,
					.container {
						#tab-shop {
							margin: 0;
						}
						#filters_box {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							#filter_box_offcanvas {
								width: 600px;
								color: ezentrum_variables.$body-font-color;
								.offcanvas-header {
									background-color:ezentrum_custom_variables.$light-yellow;
									padding-left: 19px;
									padding-right: 25px;
									display: block;
									position: relative;
									.offcanvas-title {
										color: ezentrum_variables.$body-font-color;
										font-size: 1.2rem;
										font-weight: 500;
										text-transform: uppercase;
									}
									.btn-close {
										position: absolute;
										right: 30px;
										top: 11px;
										width: 33px;
										opacity: 1;
										background-color: transparent !important;
									}
									#ez_selected_filter {
										width: 100%;
										border: none;
										background-color: ezentrum_custom_variables.$light-yellow;
									}
								}
								.offcanvas-body {
									margin-bottom: 100px;
									padding:0;
									scroll-behavior: smooth;
									>.container-fluid {
										padding: 0;
										.row { margin: 0; }
										.col, .col-12 { padding: 0; }
										&:not(:last-of-type) {
											border-bottom: 1px solid ezentrum_custom_variables.$light-yellow;
										}
										.tab_public_element {
											margin: 0;
											height: fit-content;
											background-color: ezentrum_variables.$white;
											border: none;
											.accordion-body {
												position:relative;
												padding-bottom:0;
												&:after {
													position:absolute;
													display:block;
													left:0;
													bottom:0;
													width:100%;
													height:1px;
													background:#ffffff;
													-webkit-box-shadow:0px 0px 36px 40px #ffffff;
													-moz-box-shadow:0px 0px 36px 40px #ffffff;
													box-shadow:0px 0px 36px 40px #ffffff;
													content:"";
												}
											}
											&#ez_price_filter {
												width: 100%;
											}
											.headline {
												color: ezentrum_variables.$body-font-color;
												background-color: initial;
												box-shadow: none;
												cursor: pointer;
												font-size: ezentrum_variables.$body-font-size;
												svg.ez_icon {
													display:none;
												}
												.filter_headline {
													padding-left:0;
												}
												&[aria-expanded="true"] {
													background-color: lighten(ezentrum_custom_variables.$light-yellow, 10%);
												}
												&.ez-disabled { display: none; }
												.filter_hits {
													position: absolute;
													right: 60px;
													top: 50%;
													transform: translateY(-50%);
												}
												~.filter_collapse_area {
													&.ez_slide_filter_container {
														justify-content: center;
														align-items: center;
														.accordion-body {
															margin: ezentrum_variables.$global-margin 30px;
															&:after {
																display:none;
															}
															.ez_number_input_area {
																padding-top: 20px;
																display: flex;
																gap: 20%;
																@include ezentrum_mixins.for-size(tablet-portrait-up) {
																	gap: 30%;
																}
																input {
																	text-align:center;
																}
															}
														}
														>.accordion-body {
															.noUi-connect {
																background-color: ezentrum_variables.$primary-color;
															}
														}
													}
													.productselection {
														padding: 0;
														margin: 0;
														max-height: 350px;
														overflow-y: auto;
														.filters {
															width: 100%;
															flex: auto;
															margin: 0;
															padding: 0;
															padding-bottom:40px;
															.filter_tablehead.ez_filter.accordion-header:not(.collapsed) {
																/*
																padding-top: 5px;
																margin-top: .4rem;
																*/
																&.dropdown-toggle {
																	border-left: 3px solid ezentrum_variables.$primary-color;
																	&::after {
																		rotate: 180deg;
																	}
																}
															}
															.filter_tablehead {
																>* { cursor: pointer; }
																~.ez_filterlist {
																	>* {
																		margin-left: 24px;
																		// &:first-child { padding-top: 5px; }
																		// &:last-child { padding-bottom: 5px;}
																	}
																}
															}
															.ez_filter:hover {
																input {
																	border-color: ezentrum_variables.$alert-color;
																	&:disabled {
																		border-color: rgba(0,0,0,.25);
																	}
																}
															}
														}
													}
												}
											}
											.accordion-body.filter-produktgruppe {
												&:after {
													display:none;
												}
											}
											.headline {
												~.filter_collapse_area {
													.productselection.filter-produktgruppe {
														padding-bottom: ezentrum_variables.$global-padding;
														.filters {
															padding-bottom:0;
														}
													}
												}
											}
											.ez_filter_selection {
												// refering to the search input field inside the individual filters
												input[type=text] {
													border-color: ezentrum_variables.$primary-color;
													width: 85%;
												}
												.productselection {
													.filters {
														.ez_filter {
															&.excluded_filter {
																display: none;
															}
															.ez_form_check_input {
																display: none;
																&:checked {
																	~label {
																		.ez_icon.unchecked {
																			display:none;
																		}
																		.ez_icon.checked {
																			display:block;
																		}
																	}
																}
																~label {
																	padding-left: 1.875rem;
																	font-weight: 300;
																	:not(:hover) {
																		color:ezentrum_variables.$body-font-color;
																	}
																	cursor: pointer;
																	.ez_icon {
																		left:0;
																	}
																}
																&:disabled {
																	background-color: ezentrum_custom_variables.$light-gray;
																	pointer-events: none;
																	~label {
																		text-decoration: dashed;
																		color: ezentrum_custom_variables.$light-gray;
																		pointer-events: none;
																	}
																}
															}
														}
													}
												}
												&::after {
													display: none;
												}
											}
											#filter_brand_area, #filter_Region_area, #filter_Unterregion_area {
												.productselection {
													display: block;
													.filters {
														display: block;
														width:96%;
													}
													.ez_brandfilter, #ez-Regionfilter-Region, #ez-Unterregionfilter-Unterregion {
														display: grid;
														grid-template-columns: 100%;
														@include ezentrum_mixins.for-size(tablet-portrait-up) {
															grid-template-columns: 50% 50%;
														}
													}
												}
											}
										}
										.row:not(:last-of-type) {
											.filter_collapse_area {
												border-bottom: 1px solid ezentrum_variables.$primary-color;
											}
										}
									}
								}
								.offcanvas-footer {
									position: absolute;
									bottom: 0;
									padding-top: ezentrum_variables.$global-padding;
									width: 100%;
									height: 90px;
									border-top:1px solid ezentrum_custom_variables.$light-yellow;
									.ez_button_white {
										width: 350px;
										margin: auto;
									}
									.ez_filterbox_reset {
										width: fit-content;
										margin: 0;
										margin-left: auto;
										padding-top: 19px;
										padding-right: 19px;
									}
								}
							}
							.offcanvas-backdrop {
								background-color: darken( ezentrum_variables.$body-font-color, 1%);
							}
						}
						#search_query_string {
							.orderby {
								width:100%;
								background-color:ezentrum_variables.$white;
								border-color:ezentrum_variables.$primary-color;
								select {
									width:calc(100% - #{ezentrum_variables.$global-padding});
									text-align:center;
								}
							}
							#ez_shortcut_container {
								display: flex;
								flex-wrap: wrap;
								gap: ezentrum_variables.$global-margin;
								overflow:hidden;
								@include ezentrum_mixins.for-size(tablet-portrait-up) {
									flex-wrap: nowrap;
								}
								.ez_button_white {
									margin-bottom:ezentrum_variables.$global-margin;
									width: fit-content;
									font-size: ezentrum_variables.$body-font-size;
									line-height: calc(0.75 * #{ezentrum_variables.$body-line-height});
									&:hover {
										color: ezentrum_custom_variables.$red;
									}
									&:first-of-type {
										width: 200px;
										min-width:75px;
										background-color: ezentrum_custom_variables.$light-yellow;
										&:hover {
											background-color: ezentrum_variables.$body-font-color;
											color: ezentrum_custom_variables.$light-yellow;
											fill: ezentrum_custom_variables.$light-yellow;
										}
									}
								}
							}
						}
						.ez_filterbox_selectedfilter, .ez_filterbox_reset {
							display:inline-block;
							padding: 0 5px;
							background-color: ezentrum_variables.$white;
							white-space:nowrap;
						}
						.ez_filterbox_selectedfilter {
							margin-right: 10px;
							margin-bottom: 7px;
						}
						.ez_active_filter_area {
							padding-bottom: ezentrum_variables.$global-padding;
						}
						#recommendations_box {
							#recommendations_container {
								#rec_headline {
									font-size: 1.2rem;
									color: ezentrum_variables.$primary-color;
									font-weight: bolder;
									text-decoration: underline;
								}
								#rec_content_container {
									.information_item:first-of-type { padding-top: 5px; }
									.information_item:not(:first-of-type):not(:last-of-type) {
										padding: 10px 0;
									}
								}
							}
						}
						.ez_button_white {
							background-color: ezentrum_variables.$white;
							min-height: calc(#{ezentrum_variables.$body-font-size} + (1.6 * #{ezentrum_variables.$global-padding}));
							line-height: 1.6rem;
							text-align: center;
							font-size: ezentrum_variables.$body-font-size;
							border: 1px solid ezentrum_variables.$primary-color;
							margin: 0;
							width: 100%;
							appearance: none;
							padding: .4rem;
							&:hover { cursor: pointer;}
						}
					}
				}
			}
		}
	}
}
#ez_waiting_animation {
	display: none;
	position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 100vw;
    height: 100vh;
	&.ez_waiting_show {
		display: flex;
		.spinner, .path {
			animation-play-state: running;
		}
	}
	$offset: 5;
	$duration: 2.5s;

	.ez_spinner {
		animation: rotation $duration linear infinite;
		display: flex;
		align-items: center;
		margin: auto;
		animation-play-state: paused;
	}

	@keyframes rotation {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(270deg); }
	}

	.path {
		stroke-dasharray: $offset;
		stroke-dashoffset: 0;
		transform-origin: center;
		animation: dash $duration linear infinite, colors ($duration * 4) linear infinite;
		animation-play-state: paused;
	}

	@keyframes colors {
		0% { stroke: ezentrum_variables.$alert-color; }
		25% { stroke: ezentrum_variables.$body-font-color; }
		50% { stroke: ezentrum_variables.$alert-color; }
		75% { stroke: ezentrum_variables.$body-font-color;}
		100% { stroke: ezentrum_variables.$alert-color; }
	}

	@keyframes dash {
		0% { stroke-dashoffset: $offset; }
		50% {
			stroke-dashoffset: $offset/4;
			transform:rotate(135deg);
			stroke-dasharray: $offset * 25;
		}
		100% {
			stroke-dashoffset: $offset;
			transform:rotate(450deg);
		}
	}
}